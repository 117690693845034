import React, {useEffect, useState} from "react";
import {Marker} from "../../../../domain/map/marker";
import {MarkerCategory} from "../../../../domain/marker-category/marker-category";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, Checkbox, FormControlLabel, styled, TextField} from "@mui/material";
import CategoryAutocomplete from "../../marker-category/CategoryAutocomplete";
import {Add, Cancel, Check} from "@mui/icons-material";
import {MarkerTitle} from "./MarkerTitle";

const MarkerInformation = styled("div")(({theme}) => ({
    padding: theme.spacing(0, 1),
    '& .MuiTextField-root': {
        marginTop: theme.spacing(2),
    },
}));

const MarkerAction = styled("div")(({theme}) => ({
    textAlign: 'center',
    padding: theme.spacing(1, 0),
}));

export const MarkerEditionForm: React.FC<{
    marker: Marker
    markerCategories: MarkerCategory[],
    onCancelMarkerEdition: () => void,
    onSaveMarker: (marker: Marker) => void,
}> = ({marker, markerCategories, onCancelMarkerEdition, onSaveMarker}) => {
    const {formatMessage} = useIntl();
    const [currentMarker, setMarker] = useState<Marker>(marker);

    useEffect(() => setMarker(marker), [marker])

    const getMarkerCategories = (marker: Marker) => {
        return markerCategories
            .filter((category: MarkerCategory) => currentMarker.categories().includes(category.code))
            .sort((a, b) => marker.categories().indexOf(a.code) - marker.categories().indexOf(b.code));
    }

    return (
        <span data-testid="edition-marker-form">
            <MarkerTitle icon={currentMarker.icon(markerCategories).icon}>
                <TextField
                    value={currentMarker.name()}
                    aria-label="marker-name"
                    inputProps={{"data-testid": "marker-name"}}
                    fullWidth={true}
                    variant="standard"
                    onChange={
                        (event: React.ChangeEvent<HTMLInputElement>): void => setMarker(currentMarker.editMarker('name', event.target.value))
                    }
                />
            </MarkerTitle>
            <MarkerInformation data-testid="marker-edition-form">
                <CategoryAutocomplete
                    markerCategories={markerCategories.filter((category: MarkerCategory) => category.icon !== '')}
                    value={getMarkerCategories(marker)}
                    onChange={(selectedCategories: MarkerCategory[]): void => setMarker(currentMarker.editMarker('categories', selectedCategories))}
                />
                <TextField
                    value={currentMarker.address()}
                    label={formatMessage({id: 'address'})}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    aria-label="marker-address"
                    inputProps={{"data-testid": "marker-address"}}
                    multiline
                    fullWidth={true}
                    onChange={
                        (event: React.ChangeEvent<HTMLInputElement>): void => setMarker(currentMarker.editMarker('address', event.target.value))
                    }
                />
                <TextField
                    value={currentMarker.description()}
                    label={formatMessage({id: 'description'})}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    aria-label="marker-description"
                    inputProps={{"data-testid": "marker-description"}}
                    multiline
                    minRows={8}
                    fullWidth={true}
                    onChange={
                        (event: React.ChangeEvent<HTMLInputElement>): void => setMarker(currentMarker.editMarker('description', event.target.value))
                    }
                />
                <TextField
                    value={currentMarker.link()}
                    label={formatMessage({id: 'link'})}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    aria-label="marker-link"
                    inputProps={{"data-testid": "marker-link"}}
                    fullWidth={true}
                    onChange={
                        (event: React.ChangeEvent<HTMLInputElement>): void => setMarker(currentMarker.editMarker('link', event.target.value))
                    }
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={currentMarker.hideOnMapAccessibleThroughLink()}
                            data-testid="marker-hideOnMapAccessibleThroughLink"
                            onChange={
                                (event: React.ChangeEvent<HTMLInputElement>): void => setMarker(currentMarker.editMarker('hideOnMapAccessibleThroughLink', event.target.checked))
                            }
                            color="primary"
                        />
                    }
                    label={formatMessage({id: 'cartography.hideOnMapAccessibleThroughLink'})}
                />
            </MarkerInformation>
            <MarkerAction data-testid="marker-action">
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onCancelMarkerEdition}
                    startIcon={<Cancel/>}
                >
                    <FormattedMessage id="cancel"/>
                </Button>
                {' '}
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => onSaveMarker(currentMarker)}
                    startIcon={marker.isAddedToTheMap() ? <Check data-testid="edit-icon"/> : <Add data-testid="add-icon"/>}
                >
                    {marker.isAddedToTheMap() ? <FormattedMessage id="validate"/> : <FormattedMessage id="add"/>}
                </Button>
            </MarkerAction>
        </span>
    );
};