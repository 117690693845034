import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Snackbar} from "@mui/material";
import { Alert } from '@mui/material';
import {SnackbarCloseReason} from "@mui/material/Snackbar/Snackbar";

export type NotificationType = 'error' | 'success';
export type Notification = {
    type: NotificationType,
    message: string
};

export const notification = (message: string, type: NotificationType): Notification => ({type, message});
export const successNotification = (message: string): Notification => notification(message, 'success');
export const errorNotification = (message: string): Notification => notification(message, 'error')

export const useNotification = () => {
    const [notification, setNotification] = useState<Notification|undefined>();

    const sendSuccessNotification = (message: string) => {
        setNotification(successNotification(message))
    }

    const sendErrorNotification = (message: string) => {
        setNotification(errorNotification(message))
    }

    return {
        sendSuccessNotification,
        sendErrorNotification,
        notification
    }
}

export const NotificationBar: React.FC<{notification?: Notification, autoHideDuration?: number}> = ({notification, autoHideDuration}) => {
    const [open, setOpen] = React.useState(Boolean(notification));

    const closeNotification = (event: Event | React.SyntheticEvent<any>, reason: SnackbarCloseReason) => {
        if (reason !== 'clickaway') {
            setOpen(false);
        }
    };

    const openNotification = () => {
        if (notification) {
            setOpen(true);
        }
    };

    useEffect(openNotification, [notification]);

    if (!notification) return null;

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration || 4000}
            onClose={closeNotification}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            data-testid="notification-bar"
        >
            <Alert severity={notification?.type!} variant="filled" data-testid={`notification.${notification?.type}`}>
                <FormattedMessage id={notification?.message} />
            </Alert>
        </Snackbar>
    );
};
