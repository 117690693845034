import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {changeCategoryIcon, getAllCategoriesDataGridRows} from "./marker-category/use-cases/marker-category";
import {MarkerCategoryDataGridRow} from "./marker-category/domain/marker-category";
import {toast} from "react-toastify";
import AdminMenuDrawer from "./component/AdminMenuDrawer";
import MarkerCategoryDataGrid from "./marker-category/MarkerCategoryDataGrid";
import {PageLayout} from "../common";

const MarkerCategoryList: React.FC = () => {
    const {formatMessage} = useIntl();
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [markerCategories, setMarkerCategories] = useState<MarkerCategoryDataGridRow[]>([]);

    useEffect(() => {
        (async (): Promise<void> => {
            const categories = await getAllCategoriesDataGridRows();
            setMarkerCategories(categories);
        })();
    }, []);

    const openDrawer = () => {
        setDrawerOpen(true);
    }

    const closeDrawer = () => {
        setDrawerOpen(false);
    }

    const changeIcon = async (code: string, icon: string) => {
        try {
            await changeCategoryIcon(code, icon);
            toast.success(formatMessage({id: 'cartography.categoryIconHaveBeenChanged'}));
        } catch (e) {
            toast.error(formatMessage({id: 'formSubmissionError'}));
        }
    }

    return (
        <PageLayout
            title={formatMessage({id: "cartography.markerCategoryList"})}
            onOpenMenu={openDrawer}
        >
            <AdminMenuDrawer
                isDrawerOpen={isDrawerOpen}
                onDrawerClose={closeDrawer}
            />

            <MarkerCategoryDataGrid
                markerCategories={markerCategories}
                changeIcon={changeIcon}
            />
        </PageLayout>
    );
}

export default MarkerCategoryList;

