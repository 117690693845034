import React from "react";
import {Map} from "../../../domain/map/map";
import {Public, VpnLock} from "@mui/icons-material";

export const MapTitle: React.FC<{ map: Map }> = ({map}) => {
    return (
        <span style={{display: "flex", alignItems: "center"}}>
            {map.name()}
            {
                map.accessibleThroughLink() ?
                <Public data-testid="public-icon" style={{marginLeft: 10}}/> :
                <VpnLock data-testid="private-icon" style={{marginLeft: 10}}/>
            }
        </span>
    );
}