import React, {useContext, useEffect} from 'react';
import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import {ReadOnlyMarkerDrawer} from "../component/map/marker/MarkerDrawer";
import {MapWithMarker} from "../component/map/InteractiveMap";
import {AppContent, useIsMobileDevice} from "../../../common";
import MarkerCategoryFilterDrawer from "../component/marker-category/MarkerCategoryFilterDrawer";
import {Menu} from "@mui/icons-material";
import {useParams} from "react-router-dom";
import {InteractiveMapContext} from "../component/map/InteractiveMapContext";

const MapAccessibleThroughLink: React.FC = () => {
    const context = useContext(InteractiveMapContext);
    const isMobileDevice = useIsMobileDevice();
    const {mapId} = useParams<{ mapId: string }>();

    useEffect(() => {
        context.getMapAccessibleThroughLink(mapId)
    }, [mapId]);

    return <>
        <AppBar>
            <Toolbar>
                <IconButton
                    data-testid="app-bar-menu-button"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={context.openMarkerCategoryFiltersDrawer}
                    edge="start"
                    size="large">
                    <Menu/>
                </IconButton>
                <Typography variant="h6" noWrap>
                    {context.map.name()}
                </Typography>
            </Toolbar>
        </AppBar>
        <AppContent>
            <MarkerCategoryFilterDrawer/>
            <ReadOnlyMarkerDrawer/>
            <MapWithMarker
                hideMapGeocoder={true}
                hideMapControls={context.isMapControlsHidden(isMobileDevice)}
            />
        </AppContent>
    </>;
};

export default MapAccessibleThroughLink;
