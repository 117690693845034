import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {
    Button, DialogActions, DialogContent, DialogTitle,
} from "@mui/material";
import {MobileDialog} from "../../../../common";
import AssetGallery from "../map-gallery/AssetGallery";
import * as usecase from "../../../services/map-gallery";
import {MapGalleryWithMarkerGallery} from "../../../domain/map-gallery/map-gallery";
import {AssetUploader} from "../map-gallery/AssetUploader";

/** @internal */
export const useSelectAsset = ({selectedAssetIds}: {selectedAssetIds: string[]}) => {
    const [assetIds, setAssetIds] = useState<string[]>(selectedAssetIds);
    const [mapGallery, setMapGallery] = useState<MapGalleryWithMarkerGallery>();

    const addAssetIds = (assetId: string) => setAssetIds([...assetIds, assetId]);

    const removeAssetIds = (assetId: string) => setAssetIds(assetIds.filter((id: string) => id !== assetId));

    const openMapGalleryToAssociateAssetsToMarker = async (mapId: string, markerId: string) => {
        const mapGalleryWithMarkerGallery = await usecase.openMapGalleryToAssociateAssetsToMarker(mapId, markerId);
        setMapGallery(mapGalleryWithMarkerGallery);
        setAssetIds(mapGalleryWithMarkerGallery.markerGallery);
    };

    return {
        mapGallery,
        assetIds,
        addAssetIds,
        removeAssetIds,
        openMapGalleryToAssociateAssetsToMarker,
    }
}

/** @container */
export const SelectAssetDialog: React.FC<{
    mapId: string,
    markerId: string,
    assetUploadUrl: string,
    onSubmit: (selectedAssets: string[]) => void,
    onClose: () => void,
}> = ({
    mapId,
    markerId,
    assetUploadUrl,
    onSubmit,
    onClose,
}) => {
    const {
        assetIds,
        mapGallery,
        addAssetIds,
        removeAssetIds,
        openMapGalleryToAssociateAssetsToMarker,
    } = useSelectAsset({selectedAssetIds: []});

    useEffect(() => {
        (async () => await openMapGalleryToAssociateAssetsToMarker(mapId, markerId))();
    }, [mapId]);

    return (
        <MobileDialog
            open={true}
            fullWidth={true}
            maxWidth="lg"
            scroll="paper"
            onClose={onClose}
        >
            <DialogTitle>
                <FormattedMessage id="cartography.addAssetToMarker"/>
            </DialogTitle>
            <DialogContent dividers={true}>
                <AssetUploader
                    apiUrl={assetUploadUrl}
                    onUploadDone={async () => await openMapGalleryToAssociateAssetsToMarker(mapId, markerId)}
                />
                {
                    mapGallery &&
                    <AssetGallery
                        assetCollection={mapGallery.mapGallery}
                        enableAssetSelection={true}
                        selectedAssets={assetIds}
                        onAssetSelect={addAssetIds}
                        onAssetUnselect={removeAssetIds}
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    <FormattedMessage id="cancel"/>
                </Button>
                <Button onClick={() => onSubmit(assetIds)} color="primary">
                    <FormattedMessage id="validate"/>
                </Button>
            </DialogActions>
        </MobileDialog>
    );
};
