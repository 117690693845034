import React, {ReactElement, useContext, useState} from "react";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {
    Alert,
    AlertTitle,
    AppBar,
    Button,
    Chip,
    Divider,
    IconButton as Icon,
    List,
    ListItem,
    ListItemText,
    styled,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import {ChevronLeft, Face} from "@mui/icons-material";
import {Map} from "../../../domain/map/map";
import {TabContext, TabPanel} from '@mui/lab';
import {Cartographer} from "../../../domain/cartographer/cartographer";
import {AuthContext} from "../../../../../application/auth";
import {DrawerHeaderWithTitle, MenuDrawer} from "../../../../common";
import * as usecase from "../../../services/map";

export const MapTabPanel = styled(TabPanel)(({theme}) => ({
    padding: theme.spacing(0),
}));

export const NoMapMessage = styled(Alert)(({theme}) => ({
    margin: theme.spacing(2),
}));

export const CartographersChip = styled(Chip)(({theme}) => ({
    marginRight: theme.spacing(0.5),
}));

export const MapNameContainer = styled("div")(() => ({
    display: 'flex',
    alignItems: 'center',
}));

export const MapName = styled(Typography)(({theme}) => ({
    marginLeft: theme.spacing(1),
}));

export const MapDescription = styled(Typography)(({theme}) => ({
    padding: theme.spacing(1, 0),
}));

const MapList: React.FC<{
    maps: Map[],
    emptyMapListMessage: ReactElement,
    extractCartographerFromMap: (map: Map) => Cartographer[]
    onMapItemClick: (map: Map) => void,
}> = ({
     maps,
     onMapItemClick,
     emptyMapListMessage,
     extractCartographerFromMap
}) => {
    const {formatMessage} = useIntl();

    if (0 ===  maps.length) {
        return emptyMapListMessage;
    }

    return (
        <List>
            {maps.map((map: Map) => (
                <React.Fragment key={map.mapId()}>
                    <ListItem
                        button
                        key={map.mapId()}
                        onClick={() => onMapItemClick(map)}
                    >
                        <ListItemText
                            disableTypography={true}
                            primary={
                                <MapNameContainer>
                                    {
                                        map.tag() !== 'none' &&
                                        <Chip
                                            color={map.tag() === 'city' ? 'primary' : 'secondary'}
                                            label={formatMessage({id: map.tag()})}
                                        />
                                    }
                                    <MapName color={"textPrimary"}>
                                        {map.name()}
                                    </MapName>
                                </MapNameContainer>
                            }
                            secondary={
                                <>
                                    <MapDescription variant={"body2"} color={"textSecondary"}>
                                        {map.description()}
                                    </MapDescription>
                                    {extractCartographerFromMap(map).map((cartographer: Cartographer): ReactElement => (
                                        <CartographersChip
                                            label={cartographer.username}
                                            key={cartographer.username}
                                            icon={<Face />}
                                            variant="outlined"
                                        />
                                    ))}
                                </>
                            }
                        />
                    </ListItem>
                    <Divider />
                </React.Fragment>
            ))}
        </List>
    );
};

export const useMapListDrawer = (defaultMaps: Map[] = [], defaultIsMapListOpened: boolean = false) => {
    const [isMapListDrawerOpened, setIsMapListDrawerOpened] = useState<boolean>(defaultIsMapListOpened);
    const [maps, setMaps] = useState<Map[]>(defaultMaps);

    /** @private */
    const hasMap = (newMap) => maps.find((map: Map) => map.mapId() === newMap.mapId());

    const getCartographerMaps = async () => {
        setMaps(await usecase.getMapList());
    }

    const toggleMapListDrawer = () => {
        setIsMapListDrawerOpened(!isMapListDrawerOpened);
    }

    const closeMapListDrawer = () => {
        setIsMapListDrawerOpened(false);
    }

    const addMap = (newMap: Map) => {
        setMaps(!hasMap(newMap) ? [...(maps), newMap] : maps.map((map: Map) => map.mapId() === newMap.mapId() ? newMap : map));
    }

    return {
        maps,
        isMapListDrawerOpened,
        getCartographerMaps,
        addMap,
        toggleMapListDrawer,
        closeMapListDrawer,
    }
}

const MapListDrawer: React.FC<{
    maps?: Map[]|undefined,
    isDrawerOpened: boolean,
    onDrawerClose: () => void,
    onCreateMapButtonClick?: () => void,
    onMapSelect?: () => void,
}> = ({
    maps,
    isDrawerOpened,
    onMapSelect,
    onDrawerClose,
    onCreateMapButtonClick
}) => {
    const {formatMessage} = useIntl();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [openedTabIndex, setOpenedTabIndex] = useState<string>('yourMapsTab');

    if (!maps) {
        return null;
    }

    return (
        <MenuDrawer
            open={isDrawerOpened}
            onClose={() => {}} onOpen={() => {}}
        >
            <DrawerHeaderWithTitle>
                <Icon onClick={onDrawerClose}>
                    <ChevronLeft/>
                </Icon>
            </DrawerHeaderWithTitle>

            <TabContext value={openedTabIndex}>
                <AppBar position="static" color="transparent">
                    <Tabs
                        value={openedTabIndex}
                        centered={true}
                        onChange={(event: React.ChangeEvent<{}>, tabIndex: string) => setOpenedTabIndex(tabIndex)}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label={formatMessage({id: 'cartography.yourMaps'})} value="yourMapsTab" />
                        <Tab label={formatMessage({id: 'cartography.mapsSharedWithYou'})} value="mapsSharedWithYouTab" />
                    </Tabs>
                </AppBar>
                <MapTabPanel value="yourMapsTab" data-testid="yourMapsTab">
                    <MapList
                        maps={maps.filter((map: Map): boolean => map.isOwnedBy(authContext?.profile?.username))}
                        onMapItemClick={(map: Map) => {
                            onMapSelect && onMapSelect();
                            onDrawerClose();
                            navigate(`/maps/${map.mapId()}`);
                        }}
                        extractCartographerFromMap={(map) => map.cartographersAllowedToEditMap()}
                        emptyMapListMessage={
                            <NoMapMessage severity="info">
                                <AlertTitle>{formatMessage({id: 'cartography.mapListEmpty'})}</AlertTitle>
                                <Button onClick={onCreateMapButtonClick} color="primary" variant="outlined">
                                    {formatMessage({id: 'cartography.createFirstMap'})}
                                </Button>
                            </NoMapMessage>
                        }
                     />
                </MapTabPanel>
                <MapTabPanel value="mapsSharedWithYouTab" data-testid="mapsSharedWithYouTab">
                    <MapList
                        maps={maps.filter((map: Map): boolean => !map.isOwnedBy(authContext?.profile?.username))}
                        onMapItemClick={(map: Map) => {
                            onMapSelect && onMapSelect();
                            onDrawerClose();
                            navigate(`/maps/${map.mapId()}`);
                        }}
                        extractCartographerFromMap={
                            (map) => map.extractCartographersWithout(authContext?.profile?.username)
                        }
                        emptyMapListMessage={
                            <NoMapMessage severity="info">
                                <AlertTitle>{formatMessage({id: 'cartography.sharedMapListEmpty'})}</AlertTitle>
                            </NoMapMessage>
                        }
                    />
                </MapTabPanel>
            </TabContext>
        </MenuDrawer>
    );
};

export default MapListDrawer;
