import React, {ReactElement} from "react";
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    styled,
    Typography
} from "@mui/material";
import {ChevronLeft, Map, Person, Room, Settings} from "@mui/icons-material";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {MenuDrawer} from "../../common";


const DrawerTitle = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    minHeight: 64,
    justifyContent: 'flex-end',
}));

const MenuListItem: React.FC<{
    path: string,
    label: string,
    icon: ReactElement,
}> = ({
    path,
    label,
    icon,
}) => {
    const {formatMessage} = useIntl();
    const navigate = useNavigate();

    return (
        <ListItem
            button
            onClick={() => navigate(path)}
        >
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText
                primary={formatMessage({id: label})}
            />
        </ListItem>
    );
}
const AdminMenuDrawer: React.FC<{
    isDrawerOpen: boolean,
    onDrawerClose: () => void,
}> = ({
    isDrawerOpen,
    onDrawerClose,
}) => {
    const {formatMessage} = useIntl();

    return (
        <MenuDrawer
            onClose={() => {}} onOpen={() => {}} // Todo: migration
            open={isDrawerOpen}
        >
            <DrawerHeader>
                <Settings />
                <DrawerTitle>
                     {formatMessage({id: 'administration'})}
                </DrawerTitle>
                <IconButton onClick={onDrawerClose} data-testid='closeDrawer' size="large">
                    <ChevronLeft/>
                </IconButton>
            </DrawerHeader>

            <Divider/>

            <List>
                <MenuListItem
                    path='/admin/categories'
                    label='cartography.markerCategoryList'
                    icon={<Room />}
                />
                <MenuListItem
                    path='/admin/cartographers'
                    label='cartography.cartographerList'
                    icon={<Person />}
                />
                <MenuListItem
                    path='/maps'
                    label='cartography.mapEdition'
                    icon={<Map />}
                />
            </List>
        </MenuDrawer>
    );
};

export default AdminMenuDrawer;
