import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {MobileDialog} from "../../../../common";
import {FilePond} from "react-filepond";
import 'filepond/dist/filepond.min.css';

export const AssetUploader: React.FC<{apiUrl: string, onUploadDone?: () => void}> = ({apiUrl, onUploadDone}) => {
    const {formatMessage} = useIntl();

    return (
        <FilePond
            allowMultiple={true}
            allowRevert={false}
            maxParallelUploads={5}
            server={apiUrl}
            onprocessfiles={onUploadDone}
            name="files"
            labelIdle={formatMessage({id: 'cartography.dropzoneInput'})}
            labelFileProcessing={formatMessage({id: 'cartography.labelFileProcessing'})}
            labelFileProcessingComplete={formatMessage({id: 'cartography.labelFileProcessingComplete'})}
            labelFileProcessingAborted={formatMessage({id: 'cartography.labelFileProcessingAborted'})}
            labelFileProcessingError={formatMessage({id: 'cartography.labelFileProcessingError'})}
            labelTapToCancel={formatMessage({id: 'cartography.labelTapToCancel'})}
            labelTapToRetry={formatMessage({id: 'cartography.labelTapToRetry'})}
        />
    )
};

const AssetUploaderDialog: React.FC<{
    apiUrl: string,
    closeAddAssetsDialog: () => void,
}> = ({
    closeAddAssetsDialog,
    apiUrl,
}) => (
    <MobileDialog
        open={true}
        fullWidth={true}
        maxWidth="lg"
        onClose={closeAddAssetsDialog}
        aria-labelledby="form-dialog-title"
    >
        <DialogTitle id="form-dialog-title">
            <FormattedMessage id="cartography.addAssetsToGallery" />
        </DialogTitle>
        <DialogContent>
            <AssetUploader apiUrl={apiUrl} />
        </DialogContent>
        <DialogActions>
            <Button onClick={closeAddAssetsDialog} color="primary">
                <FormattedMessage id="close" />
            </Button>
        </DialogActions>
    </MobileDialog>
);

export default AssetUploaderDialog;