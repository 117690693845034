import React from "react"
import {styled, SwipeableDrawer} from "@mui/material";
import {SwipeableDrawerProps} from "@mui/material/SwipeableDrawer/SwipeableDrawer";

export const desktopDrawerWidth = 500;
export const MenuDrawer = styled(
    (props: SwipeableDrawerProps) => <SwipeableDrawer variant="persistent" onClose={() => {}} onOpen={() => {}} anchor={'left'} {...props} />
)(({theme}) => ({
    flexShrink: 0,
    [theme.breakpoints.up('xs')]: {
        width: '100%',
        '& .MuiDrawer-paper': {
            width: '100%',
            boxSizing: 'border-box',
            height: 'calc(100vh - 56px)',
            position: 'absolute',
            zIndex: 1300,
            bottom: 0,
        },
    },
    [theme.breakpoints.up('sm')]: {
        width: desktopDrawerWidth,
        '& .MuiDrawer-paper': {
            width: desktopDrawerWidth,
            boxSizing: 'border-box',
            height: 'calc(100vh - 64px)',
            position: 'absolute',
            zIndex: 1300,
            bottom: 0,
        },
    },
}));

export const DrawerHeaderWithTitle = styled("div")(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
}));
