import React, {ReactNode} from "react";
import {useIntl} from "react-intl";
import {Avatar, IconButton as Button, styled, Tooltip} from "@mui/material";

const IconAvatar = styled(Avatar)(({ theme }) => ({
    background: theme.palette.primary.main,
}));

const AvatarButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1)
}));

const IconButton: React.FC<{
    title: string,
    children: ReactNode
    placement?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top',
    edge?: 'start' | 'end' | false;
    id?: string,
    onClick?: () => void
}> = ({title, children, placement= 'bottom', edge, id= '', onClick}) => {
    const {formatMessage} = useIntl();

    return (
        <Tooltip
            title={formatMessage({id: title})}
            placement={placement}
            arrow
        >
            <AvatarButton
                edge={edge||false}
                onClick={onClick}
                data-testid={id}
            >
                <IconAvatar>
                    {children}
                </IconAvatar>
            </AvatarButton>
        </Tooltip>
    );
};

export default IconButton;
