import axios, {AxiosInstance, AxiosResponse} from 'axios';
import history from '../history';

type HTTPResponse<T> = AxiosResponse<T>;
type HTTPRequest = AxiosInstance;

export default class HttpClient {
    public http: HTTPRequest;

    constructor() {
        this.http = axios.create({
            baseURL: '/api',
            timeout: 4000,
            withCredentials: true,
        });
    }

    public async post(
        path: string = '/',
        payload: object = {}
    ): Promise<HTTPResponse<any>> {
        try {
            return await this.http.post(path, payload);
        } catch (e) {
            const response = e.response;
            if (401 === response.status) {
                history.push(`/login`);
            }

            throw e;
        }
    }

    public async delete(
        path: string = '/',
        payload: object = {}
    ): Promise<HTTPResponse<any>> {
        try {
            return await this.http.delete(path, payload);
        } catch (e) {
            const response = e.response;
            if (401 === response.status) {
                history.push(`/login`);
            }

            throw e;
        }
    }

    public async get(
        path: string = '/',
        payload: object = {}
    ): Promise<HTTPResponse<any>> {
        try {
            return await this.http.get(path, payload);
        } catch (e) {
            const response = e.response;
            if (401 === response.status) {
                history.push(`/login`);
            }

            throw e;
        }
    }
}
