import React from "react"
import {Dialog} from "@mui/material";
import {useIsMobileDevice} from "./useMediaQuery";
import {DialogProps} from "@mui/material/Dialog/Dialog";

export const MobileDialog = (props: DialogProps) => {
    const isMobileDevice = useIsMobileDevice();

    return <Dialog fullScreen={isMobileDevice} {...props} />
}



















