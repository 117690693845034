import HttpClient from "../../application/http/HttpClient";

const httpClient: HttpClient = new HttpClient();

export const logout = async (): Promise<void> => {
    await httpClient.post(`/logout`);
}

export const login = async (username: string, password: string): Promise<void> => {
    await httpClient.post(`/login`, {
        username,
        password,
    });
}

export const createNewAccount = async (username: string, password: string, secondPassword: string, email: string): Promise<void> => {
    await httpClient.post(`/accounts`, {
        username,
        password,
        secondPassword,
        email,
    });
}

export const resetPassword = async (password: string, secondPassword: string, token: string) => {
    await httpClient.post(`/accounts/reset-password`, {
        password,
        secondPassword,
        token,
    });
}

export const askForResetPassword = async (email: string) => {
    await httpClient.post(`/accounts/ask-for-new-password`, {
        email,
    });
}

