import {Asset, AssetCollection} from "./asset";

export class MapGallery {
    public readonly mapName: string;
    public readonly assets: AssetCollection;

    constructor(state: {
        name: string,
        assets: Asset[],
    }) {
        this.mapName = state.name;
        this.assets = new AssetCollection(state.assets);
    }
}

export class MapGalleryWithMarkerGallery {
    public readonly markerGallery: string[];
    public readonly mapGallery: AssetCollection;

    constructor(state: {
        markerGallery: string[],
        mapGallery: Asset[],
    }) {
        this.markerGallery = state.markerGallery;
        this.mapGallery = new AssetCollection(state.mapGallery);
    }
}
