import HttpClient from "../../../../application/http/HttpClient";
import {CartographerDataGridRow} from "../domain/cartographer";

const httpClient: HttpClient = new HttpClient();

export const getAllCartographerDataGridRows = async (): Promise<CartographerDataGridRow[]> => {
    const response = await httpClient.get(`/admin/cartographers`);

    return response.data;
}

export const toggleCartographerPremiumStatus = async (cartographer: string, isPremium: boolean): Promise<void> => {
    await httpClient.post(`/admin/cartographer/toggleCartographerPremiumStatus`, {
        cartographer,
        isPremium,
    });
}
