import React, {useContext, ReactNode, useEffect} from "react";
import {useIntl} from "react-intl";
import {toast} from "react-toastify";
import {IntlContext} from '../../application/i18n';
import {logout} from "../../identity";
import {AppBar} from "./index";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../application/auth";
import {styled} from "@mui/material";

export const AppContent = styled("div")(({ theme }) => ({
    width: '100%',
    position: 'fixed',
    [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 56px)',
        top: 56,
    },
    [theme.breakpoints.up('sm')]: {
        height: 'calc(100vh - 64px)',
        top: 64,
    },
}));

export const PageLayout: React.FC<{
    title?: string|ReactNode,
    children: ReactNode | ReactNode[],
    onOpenMenu: () => void,
}> = ({
    title,
    children,
    onOpenMenu
}) => {
    const {formatMessage} = useIntl();
    const navigate = useNavigate();
    const intlContext = useContext(IntlContext);
    const authContext = useContext(AuthContext);

    useEffect(() => {
        authContext.login();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logoutCartographer = async () => {
        try {
            await logout();
            authContext.logout();
            navigate(`/login`);
        } catch (e) {
            toast.error(formatMessage({id: 'formSubmissionError'}));
        }
    };

    let openSettings;
    if (authContext?.profile?.isAdmin) {
        openSettings = () => {
            navigate(`/admin/categories`);
        }
    }

    return (
        <>
            <AppBar
                username={authContext?.profile?.username}
                email={authContext?.profile?.email}
                title={title}
                locale={intlContext.locale}
                onOpenMenu={onOpenMenu}
                onLogout={logoutCartographer}
                onOpenSettings={openSettings}
                onLanguageSwitch={(language: string): void => {
                    intlContext.selectLanguage(language)
                }}
            />
            <AppContent>
                {children}
            </AppContent>
        </>
    );
};
