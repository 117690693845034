export type MarkerCategoryState = {
    code: string,
    icon: string,
    iconColor: string,
    labelEn: string,
    labelFr: string
}

export class MarkerCategory {
    public readonly code: string;
    public readonly translations: {[locale: string]: string};
    public readonly icon: string;
    public readonly iconColor: string;

    constructor(state: MarkerCategoryState) {
        this.code = state.code;
        this.translations = {
            en: state.labelEn,
            fr: state.labelFr,
        };
        this.icon = state.icon;
        this.iconColor = state.iconColor;
    }

    public label(locale: string): string {
        return this.translations[locale] !== '' ? this.translations[locale] : this.code;
    }
}
