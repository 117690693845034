import React, {ChangeEvent, FormEvent, useState} from 'react';
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import {resetPassword} from "./account/use-cases";
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import IdentityPage from "./component/IdentityPage";
import {toast} from "react-toastify";
import {Form, SubmitButton} from "./component/Form";

const ResetPassword: React.FC = () => {
    const {formatMessage} = useIntl();
    const navigate = useNavigate();
    const {token} = useParams<{token: string}>();

    const [password, setPassword] = useState<string>('');
    const [passwordConfirmed, setPasswordConfirmed] = useState<string>('');
    const [formErrors, setFormErrors] = useState<{ [key:string]:string }>();

    const onSubmit = async () => {
        try {
            await resetPassword(password, passwordConfirmed, token);
            toast.success(formatMessage({id: 'identity.passwordHasBeenReset'}));
            navigate(`/login`);
        } catch (e) {
            const response = e.response;
            if (400 === response.status) {
                setFormErrors(response.data.errors);
            } else {
                toast.error(formatMessage({id: 'formSubmissionError'}));
            }
        }
    };

    return (
        <IdentityPage title={formatMessage({id: 'identity.resetPassword'})}>
            <Form
                noValidate
                onSubmit={(event: FormEvent<HTMLFormElement>) => event.preventDefault()}
            >
                {
                    formErrors?.token &&
                    <Alert severity="error">{formErrors?.token}</Alert>
                }
                <TextField
                    error={Boolean(formErrors?.password)}
                    helperText={formErrors?.password}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={formatMessage({id: 'password'})}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    value={password}
                />
                <TextField
                    error={Boolean(formErrors?.secondPassword)}
                    helperText={formErrors?.secondPassword}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="secondPassword"
                    label={formatMessage({id: 'secondPassword'})}
                    type="password"
                    id="secondPassword"
                    autoComplete="current-password"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPasswordConfirmed(e.target.value)}
                    value={passwordConfirmed}
                />
                <SubmitButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                >
                    {formatMessage({id: 'identity.buttonResetPassword'})}
                </SubmitButton>

            </Form>
        </IdentityPage>
    );
}

export default ResetPassword;

