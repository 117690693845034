import React from 'react';
import {useIntl} from "react-intl";
import {DataGrid, GridRenderEditCellParams} from "@mui/x-data-grid";
import {CartographerDataGridRow} from "./domain/cartographer";
import {Switch} from "@mui/material";

const CartographerDataGrid: React.FC<{
    cartographers: CartographerDataGridRow[],
    onGrantToPremiumCartographer: (username: string, isPremium: boolean) => void,
}> = ({
    cartographers,
    onGrantToPremiumCartographer,
}) => {
    const {formatMessage} = useIntl();

    return (
        <DataGrid
            pagination
            pageSize={25}
            rowsPerPageOptions={[25]}
            columns={[
                {
                    field: 'username',
                    headerName: formatMessage({id: 'username'}),
                    flex: 1,
                },
                {
                    field: 'isPremium',
                    width: 150,
                    type: 'boolean',
                    disableColumnMenu: true,
                    editable: true,
                    headerName: formatMessage({id: 'isPremium'}),
                    renderEditCell: (params: GridRenderEditCellParams) => (
                        <Switch
                            checked={params.value as boolean}
                            onChange={async (event) => {
                                const value = event.target.checked;
                                const {id, field, api} = params;
                                await onGrantToPremiumCartographer(params.getValue(id, 'username') as string, value);

                                api.setEditCellValue({ id, field, value});
                                api.commitCellChange({ id, field });
                                api.setCellMode(id, field, 'view');
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    )
                },
                {
                    field: 'isAdmin',
                    width: 150,
                    type: 'boolean',
                    disableColumnMenu: true,
                    headerName: formatMessage({id: 'isAdmin'}),
                },
            ]}
            rows={cartographers}
        />
    );
}

export default CartographerDataGrid;

