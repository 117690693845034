import React, {MouseEvent, ReactElement, useState} from "react";
import {FormattedMessage} from "react-intl";
import {AppBar, Button, Hidden, IconButton, Popover, styled, Toolbar, Typography} from "@mui/material";
import {Menu, ExitToApp, AccountCircle} from "@mui/icons-material";
import SettingsIcon from '@mui/icons-material/Settings';
import Flag from "react-world-flags";

export const AppMenu = styled(Toolbar)({
    flexGrow: 1,
});

export const MenuTitle = styled(Typography)({
    flexGrow: 1,
    cursor: 'pointer',
});

export const MenuAction = styled(IconButton)({
    color: '#FFFFFF',
});

export const LogoutButton = styled(Button)({
    marginTop: 30,
    textAlign: 'center',
});

export const LanguageButton = styled(Button)({
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
    textAlign: 'center',
});

export const ProfilePopoverContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    width: 250,
    textAlign: 'center'
}));

const MapAppBar: React.FC<{
    username?: string,
    email?: string,
    locale: string,
    title?: string|ReactElement,
    onOpenSettings?: () => void,
    onOpenMenu: () => void,
    onLogout: () => void,
    onLanguageSwitch: (language: string) => void,
}> = ({
    username,
    email,
    locale,
    title,
    onOpenSettings,
    onOpenMenu,
    onLogout,
    onLanguageSwitch
}) => {
    const [profilePopoverAnchor, setProfilePopoverAnchor] = useState<HTMLButtonElement>();

    return <>
        <AppBar>
            <AppMenu>
                <IconButton
                    data-testid="openDrawer"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onOpenMenu}
                    edge="start"
                    size="large">
                    <Menu/>
                </IconButton>
                <MenuTitle
                    variant="h6"
                    noWrap
                    onClick={onOpenMenu}
                >
                    {title}
                </MenuTitle>
                {
                    onOpenSettings &&
                    <Hidden smDown>
                        <MenuAction
                            data-testid="open-settings"
                            onClick={onOpenSettings}
                        >
                            <SettingsIcon fontSize={"large"}/>
                        </MenuAction>
                    </Hidden>
                }
                <MenuAction
                    edge="end"
                    data-testid="open-profile"
                    onClick={(event: MouseEvent<HTMLButtonElement>) => setProfilePopoverAnchor(event.currentTarget)}
                >
                    <AccountCircle fontSize={"large"}/>
                </MenuAction>
            </AppMenu>
        </AppBar>

        <Popover
            open={Boolean(profilePopoverAnchor)}
            anchorEl={profilePopoverAnchor}
            onClose={() => setProfilePopoverAnchor(undefined)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <ProfilePopoverContainer>
                <AccountCircle fontSize={"large"} color={"primary"}/>
                <Typography variant={"h5"}>{username}</Typography>
                <Typography variant={"subtitle1"}>{email}</Typography>

                <LanguageButton
                    variant="contained"
                    data-testid={'en' === locale ? 'switch-locale-en-primary' : 'witch-locale-en-default'}
                    color={'en' === locale ? 'primary' : 'inherit'}
                    onClick={() => onLanguageSwitch('en')}
                >
                    <Flag code="gb" height="20"/>
                </LanguageButton>

                <LanguageButton
                    variant="contained"
                    data-testid={'fr' === locale ? 'switch-locale-fr-primary' : 'witch-locale-fr-default'}
                    color={'fr' === locale ? 'primary' : 'inherit'}
                    onClick={() => onLanguageSwitch('fr')}
                >
                    <Flag code="fr" height="20"/>
                </LanguageButton>

                <LogoutButton
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    startIcon={<ExitToApp/>}
                    onClick={onLogout}
                >
                    <FormattedMessage id="logout" />
                </LogoutButton>
            </ProfilePopoverContainer>
        </Popover>
    </>;
};

export default MapAppBar;
