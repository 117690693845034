import React, {ReactNode} from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import 'react-toastify/dist/ReactToastify.css';
import {styled} from "@mui/material";

const PageContainer = styled(Grid)(() => ({
    height: '100vh',
}));

const LeftContainer = styled(Grid)(({ theme }) => ({
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
}));

const RightContainer = styled('div')(({ theme }) => ({
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const TitleAvatar = styled(Avatar)(({ theme }) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
}));

const IdentityPage: React.FC<{
    title: string,
    children: ReactNode
}> = ({title, children}) => {
    return (
        <>
            <PageContainer container>
                <CssBaseline/>
                <LeftContainer item xs={false} sm={4} md={7}/>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <RightContainer>
                        <TitleAvatar>
                            <LockOutlinedIcon/>
                        </TitleAvatar>
                        <Typography component="h1" variant="h5">
                            {title}
                        </Typography>
                        {children}
                    </RightContainer>
                </Grid>
            </PageContainer>
        </>
    );
}

export default IdentityPage;

