import React, {ReactNode, useState} from 'react';
import {IntlProvider as IntlProviderReact} from 'react-intl';

import en from './translation/en.json';
import fr from './translation/fr.json';

type Translations = {
    [key: string]: {
        [key: string]: string
    };
};

const defaultLocale = navigator.language.split(/[-_]/)[0] || 'en';
const translations: Translations = {en, fr};

export const IntlContext = React.createContext<{locale:string, selectLanguage: (locale:string) => void}>({
    locale: defaultLocale,
    selectLanguage: (locale: string) => {},
});

const IntlProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const [locale, setLocale] = useState(defaultLocale);
    const [messages, setMessages] = useState(translations[defaultLocale]);

    const selectLanguage = (locale: string) => {
        setLocale(locale);
        setMessages(translations[locale]);
    }

    return (
        <IntlContext.Provider value={{locale, selectLanguage}}>
            <IntlProviderReact locale={locale} messages={messages}>
                {children}
            </IntlProviderReact>
        </IntlContext.Provider>
    );
};

export default IntlProvider;
