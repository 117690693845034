import {MapGallery, MapGalleryWithMarkerGallery} from "../domain/map-gallery/map-gallery";
import HttpClient from "../../../application/http/HttpClient";

const httpClient: HttpClient = new HttpClient();

export const openMapGallery = async (mapId: string): Promise<MapGallery> => {
    const response = await httpClient.get(`/maps/${mapId}/gallery`);

    return new MapGallery(response.data);
}

export const openMapGalleryToAssociateAssetsToMarker = async (mapId: string, markerId: string): Promise<MapGalleryWithMarkerGallery> => {
    const response = await httpClient.get(`/maps/${mapId}/markers/${markerId}/gallery`);

    return new MapGalleryWithMarkerGallery(response.data);
}
