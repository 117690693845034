import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import AdminMenuDrawer from "../component/AdminMenuDrawer";
import CartographerDataGrid from "./CartographerDataGrid";
import {getAllCartographerDataGridRows, toggleCartographerPremiumStatus} from "./use-cases/cartographer";
import {CartographerDataGridRow} from "./domain/cartographer";
import {toast} from "react-toastify";
import {PageLayout} from "../../common";

const CartographerList: React.FC = () => {
    const {formatMessage} = useIntl();
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [cartographers, setCartographers] = useState<CartographerDataGridRow[]>([]);

    useEffect(() => {
        (async (): Promise<void> => {
            const cartographers = await getAllCartographerDataGridRows();
            setCartographers(cartographers);
        })();
    }, []);

    const openDrawer = () => {
        setDrawerOpen(true);
    }

    const closeDrawer = () => {
        setDrawerOpen(false);
    }

    const togglePremiumStatus = async (username: string, isPremium: boolean) => {
        try {
            await toggleCartographerPremiumStatus(username, isPremium);
            toast.success(formatMessage({id: 'cartography.cartographerHasBeenChanged'}));
        } catch (e) {
            toast.error(formatMessage({id: 'formSubmissionError'}));
        }
    }

    return (
        <PageLayout
            title={formatMessage({id: "cartography.cartographerList"})}
            onOpenMenu={openDrawer}
        >
            <AdminMenuDrawer
                isDrawerOpen={isDrawerOpen}
                onDrawerClose={closeDrawer}
            />

            <CartographerDataGrid
                cartographers={cartographers}
                onGrantToPremiumCartographer={togglePremiumStatus}
            />
        </PageLayout>
    );
}

export default CartographerList;

