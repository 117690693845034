import React from "react";
import { Box, styled } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {Add, Edit, Photo} from "@mui/icons-material";
import {IconButton} from "../../../../common";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import {useIntl} from "react-intl";

const MapActionsContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    zIndex: 1000,
    width: 52,
    [theme.breakpoints.down('md')]: {
        bottom: 5,
        left: 5,
    },
    [theme.breakpoints.up('sm')]: {
        top: 10,
        right: 10,
    },
}));

export const CreateMapAction: React.FC<{onClick: () => void}> = ({onClick}) => (
    <MapActionsContainer>
        <IconButton
            title="cartography.createMap"
            placement="right"
            id="create-map"
            onClick={onClick}
        >
            <Add/>
        </IconButton>
    </MapActionsContainer>
);

const MapAction = withStyles({
    staticTooltipLabel: {
        width: 'max-content'
    }
})(SpeedDialAction);

export const MapActions: React.FC<{
    isMobileDevice: boolean,
    isPremiumCartographer: boolean,
    onCreateMapActionClick: () => void
    onEditActionClick: () => void
    onOpenGalleryActionClick: () => void
}> = ({
    isMobileDevice,
    isPremiumCartographer,
    onCreateMapActionClick,
    onEditActionClick,
    onOpenGalleryActionClick,
}) => {
    const [open, setOpen] = React.useState(false);
    const {formatMessage} = useIntl();

    const openSpeedDail = () => {
        setOpen(true);
    };

    const closeSpeedDail = () => {
        setOpen(false);
    };

    const direction = isMobileDevice ? 'up' : 'down';
    const tooltipPlacement = isMobileDevice ? 'right' : 'left';

    return (
        <MapActionsContainer>
            <SpeedDial
                ariaLabel="Map action"
                data-testid="map-actions"
                icon={<SpeedDialIcon />}
                onClose={closeSpeedDail}
                onOpen={openSpeedDail}
                direction={direction}
                open={open}
                FabProps={{
                    size: 'medium',
                }}
            >
                <MapAction
                    key="add"
                    icon={<Add />}
                    data-testid="create-map"
                    tooltipTitle={formatMessage({id: 'cartography.createMap'})}
                    tooltipPlacement={tooltipPlacement}
                    tooltipOpen
                    onClick={() => {
                        closeSpeedDail();
                        onCreateMapActionClick();
                    }}
                />
                <MapAction
                    key="edit"
                    icon={<Edit />}
                    data-testid="edit-map"
                    tooltipTitle={formatMessage({id: 'cartography.editMap'})}
                    tooltipPlacement={tooltipPlacement}
                    tooltipOpen
                    onClick={() => {
                        closeSpeedDail();
                        onEditActionClick();
                    }}
                />
                {
                    isPremiumCartographer &&
                    <MapAction
                        key="photo"
                        icon={<Photo />}
                        data-testid="map-gallery"
                        tooltipTitle={formatMessage({id: 'cartography.openGallery'})}
                        tooltipPlacement={tooltipPlacement}
                        tooltipOpen
                        onClick={() => {
                            closeSpeedDail();
                            onOpenGalleryActionClick();
                        }}
                    />
                }

            </SpeedDial>
        </MapActionsContainer>
    );
}
