import React, {Ref, useCallback, useContext, useRef} from "react";
import ReactMapGL, {
    GeolocateControl,
    MapContext,
    MapRef,
    Marker as MapboxMarker,
    NavigationControl,
    ScaleControl
} from "react-map-gl";
import {SearchResult} from "../../../domain/map/search-result";
import {MapViewport} from "../../../domain/map/map";
import {useIntl} from "react-intl";
import Geocoder from "react-map-gl-geocoder";
import {styled} from "@mui/material";
import {InteractiveMapProps} from "react-map-gl/src/components/interactive-map";
import {Marker} from "../../../domain/map/marker";
import {MarkerIcon} from "../../../../common";
import {InteractiveMapContext} from "./InteractiveMapContext";

const MapMarkerIcon = styled(MarkerIcon)(({theme}) => ({
    cursor: 'pointer',
    fill: theme.palette.info.main,
}));

export const MapMarker: React.FC<{
    marker: Marker,
    draggable: boolean,
    onClick?: (marker: Marker) => void,
    onDraggedToNewLocation: (marker: Marker, newLatitude: number, newLongitude: number) => void
}> = ({
    marker,
    draggable,
    onClick,
    onDraggedToNewLocation
}) => {
    const iconSize = 16;
    const context = useContext(InteractiveMapContext);
    let icon = marker.icon(context.markerCategories);
    const searchResultIcon = {
        icon: 'marker',
        color: '#ff2600',
    };

    if (!marker.isAddedToTheMap()) {
        icon = searchResultIcon;
    }

    return (
        <MapboxMarker
            key={marker.markerId()}
            longitude={marker.longitude()}
            latitude={marker.latitude()}
            draggable={draggable}
            onDragEnd={(event: { lngLat: [number, number] }) => {
                onDraggedToNewLocation(marker, event.lngLat[1], event.lngLat[0])
            }}
            offsetLeft={-iconSize}
            offsetTop={-iconSize}
        >
            <div style={{
                background: icon.color,
                width: iconSize,
                height: iconSize,
                borderRadius: '50%',
                padding: '4px',
                border: "2px solid #FFF"
            }}>
                <MapMarkerIcon
                    icon={icon.icon}
                    color="#FFFFFF"
                    size={iconSize}
                    onClick={() => {
                        if (!draggable) {
                            onClick && onClick(marker);
                        }
                    }}
                />
            </div>
        </MapboxMarker>
    );
};

export const MapGeocoder: React.FC<{
    geocoderContainerReference: Ref<HTMLDivElement>,
    mapReference: Ref<MapRef | null>,
    onSearchResultSelect: (event: { result: SearchResult }) => void,
    onSearchResultClear: () => void,
    onViewportChange: (mapViewport: MapViewport) => void,
}> = ({
    geocoderContainerReference,
    mapReference,
    onSearchResultSelect,
    onSearchResultClear,
    onViewportChange,
}) => {
    const {formatMessage, locale} = useIntl();
    const {viewport} = useContext(MapContext);

    return (
        <Geocoder
            trackProximity={true}
            language={locale}
            inputValue=""
            placeholder={formatMessage({id: 'cartography.geocoderPlaceholder'})}
            marker={false}
            onResult={onSearchResultSelect}
            zoom={viewport && 16 < viewport?.zoom ? viewport?.zoom : 16}
            onClear={onSearchResultClear}
            containerRef={geocoderContainerReference}
            mapRef={mapReference}
            onViewportChange={onViewportChange}
            mapboxApiAccessToken="pk.eyJ1IjoiYXJub2xhbmdsYWRlIiwiYSI6ImNrMW96MnpqYzBsOTkzb29pb2hvNWRyeDQifQ.tZHIS8QcR0QB9i9mkQeTOg"
        />
    )
}

const MapGeocoderContainer = styled("div")(({theme}) => ({
    position: 'absolute',
    left: 10,
    top: 10,
    zIndex: 900,
    [theme.breakpoints.up('xs')]: {
        width: 'calc(100% - 20px)',
    },
    [theme.breakpoints.up('sm')]: {
        width: 480,
    },
}));


export type MapGlProps = InteractiveMapProps & {
    hideMapGeocoder?: boolean,
    hideMapControls?: boolean,
    searchResult?: Marker,
    onSearchResultSelect?: (marker: Marker) => void,
    onSearchResultClear?: () => void,
    handleGeocoderViewportChange?: (mapViewport: MapViewport) => void,
    onSearchResultDrag?: (marker: Marker, newLatitude: number, newLongitude: number) => void,
}

export const MapGL: React.FC<MapGlProps> = ({
    hideMapGeocoder,
    hideMapControls,
    searchResult,
    onSearchResultSelect,
    onSearchResultClear,
    handleGeocoderViewportChange,
    children,
    onSearchResultDrag,
    ...props
}) => {
    const mapReference = useRef<MapRef | null>(null);
    const geocoderContainerReference = useRef<HTMLDivElement | null>(null);

    const onSearchResultSelectMemoized = useCallback((event: { result: SearchResult }) => {
        onSearchResultSelect && onSearchResultSelect(Marker.fromSearchResult(event.result));
    }, [onSearchResultSelect]);

    const onSearchResultClearMemoized = useCallback(
        () => onSearchResultClear && onSearchResultClear(),
        [onSearchResultClear]
    );

    return (
        <>
            <MapGeocoderContainer ref={geocoderContainerReference}/>
            <ReactMapGL
                {...props}
                ref={mapReference}
                width="100%"
                height="100%"
                mapStyle="mapbox://styles/mapbox/streets-v11?optimize=true"
                mapboxApiAccessToken="pk.eyJ1IjoiYXJub2xhbmdsYWRlIiwiYSI6ImNrMW96MnpqYzBsOTkzb29pb2hvNWRyeDQifQ.tZHIS8QcR0QB9i9mkQeTOg"
            >
                {
                    !hideMapGeocoder && onSearchResultSelect && onSearchResultClear && handleGeocoderViewportChange && onSearchResultDrag &&
                    <>
                        <MapGeocoder
                            data-testid="map-geocoder"
                            onViewportChange={handleGeocoderViewportChange}
                            onSearchResultSelect={onSearchResultSelectMemoized}
                            onSearchResultClear={onSearchResultClearMemoized}
                            geocoderContainerReference={geocoderContainerReference}
                            mapReference={mapReference}
                        />

                        {
                            searchResult &&
                            <MapMarker
                                marker={searchResult}
                                draggable={true}
                                onDraggedToNewLocation={onSearchResultDrag}
                            />
                        }
                    </>
                }

                {children}

                {
                    !hideMapControls &&
                    <>
                        <GeolocateControl
                            positionOptions={{enableHighAccuracy: true}}
                            style={{right: 10, bottom: 30}}
                            trackUserLocation={true}
                        />
                        <NavigationControl style={{right: 10, bottom: 64}}/>
                        <ScaleControl
                            maxWidth={100}
                            style={{left: 100, bottom: 10}}
                            unit="metric"
                        />
                    </>
                }

            </ReactMapGL>
        </>
    )
}