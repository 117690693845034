import HttpClient from "../../../../application/http/HttpClient";
import {MarkerCategoryDataGridRow} from "../domain/marker-category";

const httpClient: HttpClient = new HttpClient();

export const getAllCategoriesDataGridRows = async (): Promise<MarkerCategoryDataGridRow[]> => {
    const response = await httpClient.get(`/admin/categories`);

    return response.data.map(
        (category: {code: string, icon: string, labelEn: string, labelFr: string}): MarkerCategoryDataGridRow => {
            return {
                ...category,
                ...{id: category.code}
            }
        }
    );
}

export const changeCategoryIcon = async (categoryCode: string, icon: string): Promise<void> => {
    await httpClient.post(`/admin/categories/change-icon`, {
        categoryCode,
        icon
    });
}
