import React, {ChangeEvent, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Map} from "../../../domain/map/map";
import {Cartographer} from "../../../domain/cartographer/cartographer";
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup,
    TextField,
} from "@mui/material";
import CartographerAutocomplete from "../cartographer/CartographerAutocomplete";
import {Done, FileCopy} from '@mui/icons-material';
import {toast} from "react-toastify";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { green } from '@mui/material/colors';
import {MobileDialog} from "../../../../common";

const MapDialog: React.FC<{
    map?: Map,
    onClose: () => void,
    onFormSubmission: (map: Map) => void,
}> = ({map, onClose, onFormSubmission}) => {
    const {formatMessage} = useIntl();
    const [workingMap, setWorkingMap] = useState<Map>();
    const [formErrors, setFormErrors] = useState<{ [key:string]:string }>();
    const [urlCopied, setUrlCopied] = useState<boolean>(false);

    useEffect(() => {
        setWorkingMap(map);
    }, [map]);

    const submitForm = async () => {
        try {
            if (workingMap) {
                await onFormSubmission(workingMap);
            }
            setWorkingMap(undefined)
            setFormErrors({});
        } catch (e) {
            const response = e.response;
            if (400 === response.status) {
                setFormErrors(response.data.errors);
            } else {
                toast.error(formatMessage({id: 'formSubmissionError'}));
            }
        }
    };

    const closeDialog = () => {
        onClose();
        setWorkingMap(undefined)
        setFormErrors({});
        setUrlCopied(false);
    }

    const getReadOnlyMapUrl = () => {
        return window.location.protocol+'//'+window.location.host+'/maps/'+workingMap?.mapId()+'/through-link';
    }

    return (
        <MobileDialog
            open={Boolean(workingMap)}
            fullWidth={true}
            onClose={closeDialog}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {formatMessage({id: map?.mapId() ? 'cartography.editMap' : 'cartography.createNewMap'})}
            </DialogTitle>
            <DialogContent>
                <TextField
                    error={Boolean(formErrors?.name)}
                    helperText={formErrors?.name}
                    autoFocus
                    margin="dense"
                    id="name"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={formatMessage({id: 'name'})}
                    fullWidth
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setWorkingMap(workingMap?.editName(e.target.value))}
                    value={workingMap?.name()||''}
                />
                <TextField
                    error={Boolean(formErrors?.description)}
                    helperText={formErrors?.description}
                    margin="dense"
                    multiline
                    minRows={4}
                    id="description"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={formatMessage({id: 'description'})}
                    fullWidth
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setWorkingMap(workingMap?.editDescription(e.target.value))}
                    value={workingMap?.description()||''}
                />
                <FormControl component="fieldset">
                    <FormLabel component="legend" style={{transform: 'translate(0, 1.5px) scale(0.75)'}}>
                        {formatMessage({id: 'tags'})}
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-label="tag"
                        value={workingMap?.tag()||''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setWorkingMap(workingMap?.editTag(e.target.value))}
                    >
                        <FormControlLabel value="travel" control={<Radio />} label={formatMessage({id: 'travel'})} />
                        <FormControlLabel value="city" control={<Radio />} label={formatMessage({id: 'city'})} />
                        <FormControlLabel value="none" control={<Radio />} label={formatMessage({id: 'none'})} />
                    </RadioGroup>
                </FormControl>
                <CartographerAutocomplete
                    value={workingMap?.cartographersAllowedToEditMap()||[]}
                    label="cartography.cartographersAllowedToEditMap"
                    placeholder="cartography.addCartographer"
                    onChange={(cartographers: Cartographer[]) => {
                        setWorkingMap(workingMap?.editCartographersAllowedToEditMap(cartographers))
                    }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={workingMap?.accessibleThroughLink()||false}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setWorkingMap(workingMap?.editAccessibleThroughLink(event.target.checked));
                            }}
                            color="primary"
                        />
                    }
                    label={formatMessage({id: 'cartography.accessibleThroughLink'})}
                />
                {
                    map?.mapId() && map?.accessibleThroughLink() &&
                    <CopyToClipboard
                        text={getReadOnlyMapUrl()}
                        onCopy={() => setUrlCopied(true)}
                    >
                        <TextField
                            fullWidth
                            value={getReadOnlyMapUrl()}
                            label={formatMessage({id: 'cartography.clickToCopyUrl'})}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    {urlCopied ? <Done style={{color: green[500]}}/> : <FileCopy/>}
                                </InputAdornment>
                            }}
                        />
                    </CopyToClipboard>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">
                    {formatMessage({id: 'cancel'})}
                </Button>
                <Button onClick={submitForm} color="primary">
                    {formatMessage({id: 'validate'})}
                </Button>
            </DialogActions>
        </MobileDialog>
    );
};

export default MapDialog;
