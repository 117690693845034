import {PhotoProps} from "react-photo-gallery";

export type Asset = {
    assetId: string,
    thumbnail: string,
    preview: string,
    width: number,
    height: number,
}

export class AssetCollection {
    private assets: Asset[];

    constructor(assets: Asset[]) {
        this.assets = assets;
    }

    public thumbnails(): PhotoProps[] {
        return this.assets.map((asset: Asset) => ({
            src: asset.thumbnail,
            key: asset.assetId,
            width: asset.width,
            height: asset.height,
        }));
    }

    public previews(index: number): string {
        return this.assets[index]?.preview || ''
    }

    public assetIds(): string[] {
        return this.assets.map((asset: Asset) => asset.assetId);
    }

    public isEmpty(): boolean {
        return this.assets.length === 0;
    }
}