import {iconSet} from "../../../common";

export type SearchResult = {
    center: number[],
    id: string,
    place_type: string,
    text: string,
    place_name: string,
    properties: Properties
}
type Properties = {
    address?: string,
    category?: string,
    maki?: string
    wikidata?: string
}

export class SearchResultProperties {
    private properties: Properties;

    constructor(properties: Properties) {
        this.properties = properties;
    }

    public extractIcon(): string {
        return iconSet.matchFirstIconCode(this.extractCategories());
    }

    public extractCategories(): string[] {
        let categories = this.properties.category?.split(',')
            .map((category: string): string => category.trim().replace(' ', '-')) || [];

        if (this.properties.maki) {
            categories = this.removeMakiIconFromCategories(categories);
            categories = this.addMakiIconAsFirstCategory(categories);
        }

        return categories;
    }

    private removeMakiIconFromCategories(categories: string[]): string[] {
        return categories.filter((category: string) => category !== this.properties.maki);
    }

    private addMakiIconAsFirstCategory(categories: string[]): string[] {
        if (!this.properties.maki) {
            return [];
        }

        return [this.properties.maki, ...categories];
    }
}