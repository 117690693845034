import React, {useContext} from 'react';
import {useIntl} from "react-intl";
import {Box, Chip, styled, TextField} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import {MarkerIcon} from "../../../../common";
import {MarkerCategory} from "../../../domain/marker-category/marker-category";
import {AuthContext} from "../../../../../application/auth";
import {AutocompleteRenderGetTagProps} from "@mui/material/Autocomplete/Autocomplete";

export const CategoryOption = styled("span")(({theme}) => ({
    margin: theme.spacing(0, 1),
}));

const CategoryAutocomplete: React.FC<{
    markerCategories: MarkerCategory[],
    value: MarkerCategory[],
    onChange: (category: MarkerCategory[]) => void,
}> = ({
    markerCategories,
    value,
    onChange,
}) => {
    const {formatMessage, locale} = useIntl();
    const authContext = useContext(AuthContext);

    return (
        <Autocomplete
            multiple
            freeSolo={authContext.isAdmin()}
            options={markerCategories}
            aria-label="categories-autocomplete"
            value={value}
            getOptionLabel={(option) => (option as MarkerCategory).label(locale)}
            isOptionEqualToValue={(option: MarkerCategory, value: MarkerCategory): boolean => {
                return option.code === value.code;
            }}
            renderTags={(categories: MarkerCategory[], getTagProps: AutocompleteRenderGetTagProps) => {
                return categories.map((category: MarkerCategory, index: number) => (
                    <Chip
                        {...getTagProps({index})}
                        icon={<MarkerIcon icon={category.icon}/>}
                        variant="outlined"
                        key={index}
                        label={category.label(locale)}
                    />
                ))
            }}
            renderOption={(props, category: MarkerCategory) => (
                <Box component="li" marginX={1} marginY={1} {...props}>
                    <MarkerIcon icon={category.icon}/>
                    <CategoryOption>{category.label(locale)}</CategoryOption>
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{marginTop: 0}}
                    label={formatMessage({id: 'cartography.categories'})}
                    placeholder={formatMessage({id: 'cartography.addCategoriesToMarker'})}
                />
            )}
            onChange={(event: any, selectedCategories: (string | MarkerCategory)[] | null) => {
                if (null !== selectedCategories) {
                    onChange(
                        selectedCategories.map((category: MarkerCategory | string): MarkerCategory => {
                            if (typeof category === "string") {
                                return new MarkerCategory(
                                    {code: category, icon: category, iconColor: '', labelEn: category, labelFr: ''}
                                );
                            }

                            return category;
                        })
                    );
                }
            }}
        />
    );
}

export default CategoryAutocomplete;

