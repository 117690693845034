import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {Box, TextField} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import {Cartographer} from "../../../domain/cartographer/cartographer";
import {askForAllCartographers} from "../../../services/cartographer";

const CartographerAutocomplete: React.FC<{
    value?: Cartographer[],
    label: string,
    placeholder: string,
    onChange: (cartographer: Cartographer[]) => void,
}> = ({
          value,
          label,
          placeholder,
          onChange
      }) => {
    const {formatMessage} = useIntl();
    const [cartographers, setCartographers] = useState<Cartographer[]>([]);

    useEffect(() => {
        (async (): Promise<void> => {
            const cartographers = await askForAllCartographers();
            setCartographers(cartographers);
        })();
    }, []);

    return (
        <Autocomplete
            multiple
            options={cartographers}
            aria-label="cartographers-autocomplete"
            value={value||[]}
            getOptionLabel={(option: Cartographer) => option.username}
            isOptionEqualToValue={(option: Cartographer, value: Cartographer): boolean => {
                return option.username === value.username;
            }}
            renderOption={(props, cartographer: Cartographer) => (
                <Box component="li" marginX={1} marginY={1}  {...props}>
                    {cartographer.username}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={formatMessage({id: label})}
                    placeholder={formatMessage({id: placeholder})}
                />
            )}
            onChange={(event: any, selectedCartographers: Cartographer[] | null) => {
                if (null !== selectedCartographers) {
                    onChange(
                        selectedCartographers.map((Cartographer: Cartographer): Cartographer => {
                            return Cartographer;
                        })
                    );
                }
            }}
        />
    );
}

export default CartographerAutocomplete;

