import React, {ReactNode, useState} from 'react';
import {Profile} from "../../cartography/map-board/domain/cartographer/cartographer";
import {getProfile} from "../../cartography/map-board/services/cartographer";

export const AuthContext = React.createContext<{
    profile?: Profile,
    login: () => void,
    logout: () => void,
    isPremiumCartographer: () => boolean,
    isAdmin: () => boolean,
}>({
    profile: undefined,
    login: () => {},
    logout: () => {},
    isPremiumCartographer: () => false,
    isAdmin: () => false,
});

const AuthProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const [profile, setProfile] = useState<Profile>();

    const login = async () => {
        const profile = await getProfile();
        setProfile(profile);
    };

    const logout = () => setProfile(undefined);

    const isPremiumCartographer = () => profile?.isPremium||false;

    const isAdmin = () => profile?.isAdmin||false;

    return (
        <AuthContext.Provider value={{profile, login, logout, isPremiumCartographer, isAdmin}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
