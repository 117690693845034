import React, {useContext, useState} from "react";
import {ChevronLeft} from "@mui/icons-material";
import {Divider, Hidden, IconButton as Icon} from "@mui/material";
import {DrawerHeaderWithTitle, MenuDrawer, useIsMobileDevice} from "../../../../../common";
import {AuthContext} from "../../../../../../application/auth";
import {
    MarkerActions,
    MarkerDetailHeader,
    MarkerDetailProperties,
    MarkerVisibility
} from "./MarkerDetail";
import {MarkerEditionForm} from "./MarkerEditionForm";
import {InteractiveMapContext} from "../InteractiveMapContext";
import AssetGallery from "../../map-gallery/AssetGallery";
import {SelectAssetDialog} from "../SelectAssetDialog";

const CloseMarkerDrawer: React.FC<{onClick: () => void}> = ({onClick}) => (
    <>
        <DrawerHeaderWithTitle>
            <Icon data-testid="close-drawer" onClick={onClick}>
                <ChevronLeft/>
            </Icon>
        </DrawerHeaderWithTitle>
        <Divider />
    </>
);

export const mobileMarkerDrawerHeight = 300;

/** @internal */
export const useEditionMarkerDrawer = ({
    addAssetToMaker, isDialogOpened
}: {
    addAssetToMaker: (selectedAssets: string[]) => void,
    isDialogOpened: boolean
}) => {
    const [isAssetDialogOpened, setIsAssetDialogOpened] = useState<boolean>(isDialogOpened);

    const openAssetDialog = () => setIsAssetDialogOpened(true);

    const closeAssetDialog = () => setIsAssetDialogOpened(false);

    const addAssetToMakerGallery = (selectedAssets: string[]) => {
        addAssetToMaker(selectedAssets);
        closeAssetDialog();
    }

    const assetUploadUrl = (mapId: string, markerId: string) => {
        return `/api/maps/${mapId}/marker/${markerId}/gallery`;
    }

    return {
        isAssetDialogOpened,
        openAssetDialog,
        closeAssetDialog,
        addAssetToMakerGallery,
        assetUploadUrl,
    }
}

/** @container */
export const EditionMarkerDrawer: React.FC = () => {
    const context = useContext(InteractiveMapContext);
    const {isPremiumCartographer} = useContext(AuthContext);
    const isMobileDevice = useIsMobileDevice();
    const {
        isAssetDialogOpened,
        openAssetDialog,
        closeAssetDialog,
        addAssetToMakerGallery,
        assetUploadUrl,
    } = useEditionMarkerDrawer({
        addAssetToMaker: context.addAssetToMaker,
        isDialogOpened: false
    });
    return (
        <MenuDrawer
            anchor={isMobileDevice ? 'bottom' : 'left'}
            open={context.isMarkerDrawerOpened()}
            onClose={() => {}} onOpen={() => {}}
            sx={{
                '& .MuiDrawer-paper': {
                    height: {
                        xs: mobileMarkerDrawerHeight,
                        sm: '100%',
                    },
                    paddingTop: {
                        sm: '56px'
                    },
                    zIndex: {
                        xs: 1300,
                        sm: 900,
                    },
                }
            }}
        >
        <Hidden smUp>
            <CloseMarkerDrawer onClick={context.closeMarkerDrawer} />
        </Hidden>
        {
            context.openedMarker && (
                context.isMarkerEditionModeEnabled ?
                <MarkerEditionForm
                    data-testid="marker-edition-form"
                    marker={context.openedMarker}
                    markerCategories={context.markerCategories}
                    onCancelMarkerEdition={context.cancelMarkerEdition}
                    onSaveMarker={context.saveMaker}
                /> :
                <span data-testid="marker-detail">
                    <MarkerDetailHeader marker={context.openedMarker} markerCategories={context.markerCategories} />
                    <MarkerActions
                        isPremiumCartographer={isPremiumCartographer()}
                        onAssetDialogOpen={openAssetDialog}
                        onDeleteMarker={context.deleteMarker}
                        onEnableEditionForm={context.enableMarkerEdition}
                    />
                    <Divider/>
                    <MarkerDetailProperties marker={context.openedMarker} markerCategories={context.markerCategories}>
                        <MarkerVisibility map={context.map} marker={context.openedMarker} />
                    </MarkerDetailProperties>
                    <AssetGallery assetCollection={context.openedMarker.markerGallery()} />
                    {isAssetDialogOpened &&
                        <SelectAssetDialog
                            mapId={context.map.mapId()}
                            markerId={context.openedMarker.markerId()}
                            assetUploadUrl={assetUploadUrl(context.map.mapId(), context.openedMarker.markerId())}
                            onSubmit={addAssetToMakerGallery}
                            onClose={closeAssetDialog}
                        />
                    }
                </span>
            )
        }
        </MenuDrawer>
    );
};

export const ReadOnlyMarkerDrawer: React.FC = () => {
    const context = useContext(InteractiveMapContext);
    const isMobileDevice = useIsMobileDevice();

    return (
        <MenuDrawer
            anchor={isMobileDevice ? 'bottom' : 'left'}
            open={context.isMarkerDrawerOpened()}
            onClose={() => {}} onOpen={() => {}}
            sx={{
                '& .MuiDrawer-paper': {
                    height: {
                        xs: mobileMarkerDrawerHeight,
                        sm: '100%',
                    },
                }
            }}
        >
            <CloseMarkerDrawer onClick={context.closeMarkerDrawer} />
            {
                context.openedMarker &&
                <span data-testid="marker-detail">
                    <MarkerDetailHeader
                        marker={context.openedMarker}
                        markerCategories={context.markerCategories}
                    />
                    <MarkerDetailProperties
                        marker={context.openedMarker}
                        markerCategories={context.markerCategories}
                    />
                </span>
            }
        </MenuDrawer>
    );
};
