import {Map, MapViewport} from "../../../domain/map/map";
import {NotificationBar, useNotification} from "../../../../../application/notifications";
import React, {ReactNode, useEffect, useState} from "react";
import {Marker} from "../../../domain/map/marker";
import {MarkerCategory} from "../../../domain/marker-category/marker-category";
import * as usecase from "../../../services/map";
import {askForAllCategories} from "../../../services/marker-category";
import {useNavigate} from "react-router-dom";
import {useMapListDrawer} from "./MapListDrawer";

export const useInteractiveMap = (
    defaultMap?: Map,
    defaultMapProperties?: Map,
    defaultOpenedMarker?: Marker,
    defaultSearchResult?: Marker,
    defaultMaps: Map[] = [],
    defaultIsMapListOpened: boolean = false,
    defaultIsMarkerCategoriesDrawerOpened: boolean = false
) => {
    const navigate = useNavigate();
    const {notification, sendErrorNotification, sendSuccessNotification} = useNotification();
    const mapListDrawer = useMapListDrawer(defaultMaps, defaultIsMapListOpened);

    const [map, setMap] = useState<Map>(defaultMap ? defaultMap : Map.create());
    const [mapProperties, setMapProperties] = useState<Map|undefined>(defaultMapProperties);
    const [openedMarker, setOpenedMarker] = useState<Marker|undefined>(defaultOpenedMarker);
    const [isMarkerEditionModeEnabled, setIsMarkerEditionModeEnabled] = useState<boolean>(false);
    const [searchResult, setSearchResult] = useState<Marker|undefined>(defaultSearchResult);
    const [defaultMapViewport, setDefaultMapViewport] = useState<MapViewport>({width: 1, height: 1});
    const [selectedMarkerCategories, setSelectedMarkerCategories] = React.useState<string[]>([]);
    const [isMarkerCategoriesDrawerOpened, setMarkerCategoriesDrawerState] = React.useState<boolean>(defaultIsMarkerCategoriesDrawerOpened);
    const [markerCategories, setMarkerCategories] = useState<MarkerCategory[]>([]);
    const [newMarkerPosition, setNewMarkerPosition] = useState<{latitude: number, longitude: number}>();

    /** @private */
    const refreshMap = (map: Map, marker: Marker) => {
        setMap(map);
        setSelectedMarkerCategories(map.markerCategories()
            .filter((categoryCode: string) => selectedMarkerCategories.includes(categoryCode) || marker.categories().includes(categoryCode)));
    };

    /** @private */
    const disableMarkerEdition = () => {
        setIsMarkerEditionModeEnabled(false)
    };

    /** @private */
    const addMarkerToTheMap = async (marker: Marker) => {
        const newMap = await usecase.addMarkerToMap(map.mapId(), marker);
        openMarkerDrawer(newMap.getLastAddedMarker());
        sendSuccessNotification('cartography.markerHasBeenAddedToMap');

        return newMap;
    }

    /** @private */
    const editMarkerOnTheMap = async (marker: Marker) => {
        const newMap = await usecase.editMarkerOnMap(map.mapId(), marker);
        openMarkerDrawer(newMap.getMarkerById(marker.markerId()));
        sendSuccessNotification('cartography.markerHasBeenEdited');

        return newMap;
    }

    /** @private */
    const createNewMap = async (map: Map) => {
        const newMap = await usecase.createNewMap(map);
        mapListDrawer.addMap(newMap);
        sendSuccessNotification('cartography.mapHaveBeenCreated');
        setMapProperties(undefined);
        navigate(`/maps/${newMap.mapId()}`)
    }

    /** @private */
    const editMapProperties = async (map: Map) => {
        const editedMap = await usecase.editMap(map);
        setMap(editedMap);
        mapListDrawer.addMap(editedMap);
        sendSuccessNotification('cartography.mapHaveBeenEdited');
        setMapProperties(undefined);
    }

    /** @private */
    const initMapAfterLoading = (map: Map) => {
        setDefaultMapViewport(map.refreshViewport({width: window.innerWidth, height: window.innerHeight}));
        setMap(map);
        setSelectedMarkerCategories(map.markerCategories());
    }

    const openMarkerDrawer = (marker?: Marker) => {
        setOpenedMarker(marker);
        disableMarkerEdition();
        closeMarkerCategoryFiltersDrawer();
        mapListDrawer.closeMapListDrawer();
    };

    const isMarkerDrawerOpened = (): boolean => {
        return Boolean(openedMarker);
    };

    const closeMarkerDrawer = () => {
        setOpenedMarker(undefined);
        setSearchResult(undefined);
        setNewMarkerPosition(undefined);
        disableMarkerEdition();
    };

    /**
     * TODO: reset new marker position when the drawer is closed without being saved
     */
    const moveMarker = (marker: Marker, latitude: number, longitude: number) => {
        if (searchResult) {
            setSearchResult(searchResult.move(latitude, longitude))
        }

        if (openedMarker) {
            setMap(map.moveMarkerToNewLocation(openedMarker.markerId(), latitude, longitude));
            setNewMarkerPosition({latitude, longitude});
        }
    };

    const isDraggableMarker = (marker: Marker): boolean => {
        return marker.isIdentifiedBy(openedMarker?.markerId()) && isMarkerEditionModeEnabled;
    }

    const addMarkerManually = (marker: Marker) => {
        openSearchResultDrawer(marker);
    };

    const openSearchResultDrawer = (marker: Marker) => {
        setSearchResult(marker);
        setOpenedMarker(marker);
        enableMarkerEdition();
    };

    const toggleSelectedMarkerCategories = (markerCategories: string[]) => setSelectedMarkerCategories(markerCategories);

    const openMarkerCategoryFiltersDrawer = () => {
        setMarkerCategoriesDrawerState(true);
        closeMarkerDrawer();
    };

    const closeMarkerCategoryFiltersDrawer = () => setMarkerCategoriesDrawerState(false);

    const enableMarkerEdition = () => setIsMarkerEditionModeEnabled(true);

    const cancelMarkerEdition = () => {
        openedMarker?.isAddedToTheMap() ? disableMarkerEdition() : closeMarkerDrawer();
    };

    const saveMaker = async (marker: Marker) => {
        try {
            let newMarker = marker
            if (newMarkerPosition) {
                newMarker = marker.move(newMarkerPosition.latitude, newMarkerPosition.longitude);
            }
            const newMap = marker.isAddedToTheMap() ? await editMarkerOnTheMap(newMarker) : await addMarkerToTheMap(newMarker);
            refreshMap(newMap, newMarker);
            setNewMarkerPosition(undefined);
        } catch (e) {
            sendErrorNotification('formSubmissionError');
        }
    }

    const deleteMarker = async () => {
        if (!openedMarker) return;
        try {
            const newMap = await usecase.deleteMarkerFromMap(map.mapId(), openedMarker);
            refreshMap(newMap, openedMarker);
            closeMarkerDrawer();
            sendSuccessNotification('cartography.markerHasBeenDeletedFromMap');
        } catch (e) {
            sendErrorNotification('formSubmissionError');
        }
    }

    const addAssetToMaker = async (selectedAssets: string[]) => {
        if (!openedMarker) return;
        try {
            const newMap = await usecase.associateAssetsToMarker(map.mapId(), openedMarker.markerId(), selectedAssets);
            setMap(newMap);
            setOpenedMarker(newMap.getMarkerById(openedMarker.markerId()));
            sendSuccessNotification('cartography.assetAssociatedToMarker');
        } catch (error) {
            sendErrorNotification('formSubmissionError');
        }
    }

    const toggleMapListDrawer = () => {
        mapListDrawer.toggleMapListDrawer();
        if (!mapListDrawer.isMapListDrawerOpened) {
            closeMarkerCategoryFiltersDrawer();
            closeMarkerDrawer();
        }
    }

    const openMapCreationDialog = () => {
        setMapProperties(Map.create);
    }

    const openMapEditionDialog = () => {
        setMapProperties(map);
    }

    const closeMapDialog = () => {
        setMapProperties(undefined);
    }

    const saveMap = async (map: Map) => {
        map.mapId() ? await editMapProperties(map) : await createNewMap(map);
    }

    const getMap = async (mapId: string) => {
        const openedMap = await usecase.getMap(mapId);
        initMapAfterLoading(openedMap);
    }

    const getMapAccessibleThroughLink = async (mapId: string) => {
        const openedMap = await usecase.getMapAccessibleThroughLink(mapId);
        initMapAfterLoading(openedMap);
    }

    const isMapControlsHidden = (isMobileDevice: boolean): boolean => {
        return isMobileDevice && isMarkerDrawerOpened();
    }

    const isMarkerCategoryFiltersButtonDisplayed = (isMobileDevice: boolean): boolean => {
        return !isMobileDevice || isMobileDevice && !isMarkerDrawerOpened();
    }

    useEffect(() => {
        (async (): Promise<void> => {
            setMarkerCategories(await askForAllCategories());
        })();
    }, []);

    return {
        map,
        maps: mapListDrawer.maps,
        mapProperties,
        markerCategories,
        selectedMarkerCategories,
        isMarkerCategoriesDrawerOpened,
        openedMarker,
        searchResult,
        defaultMapViewport,
        isMarkerEditionModeEnabled,
        notification,
        isMapListOpened: mapListDrawer.isMapListDrawerOpened,
        toggleMapListDrawer,
        closeMapListDrawer: mapListDrawer.closeMapListDrawer,
        openMapCreationDialog,
        openMapEditionDialog,
        closeMapDialog,
        toggleSelectedMarkerCategories,
        openMarkerCategoryFiltersDrawer,
        closeMarkerCategoryFiltersDrawer,
        addMarkerManually,
        openMarkerDrawer,
        isDraggableMarker,
        closeMarkerDrawer,
        openSearchResultDrawer,
        enableMarkerEdition,
        moveMarker,
        isMarkerDrawerOpened,
        saveMaker,
        deleteMarker,
        addAssetToMaker,
        cancelMarkerEdition,
        saveMap,
        getMap,
        getMapAccessibleThroughLink,
        getCartographerMaps: mapListDrawer.getCartographerMaps,
        isMapControlsHidden,
        isMarkerCategoryFiltersButtonDisplayed,
    }
}

export const InteractiveMapContext = React.createContext<{
    map: Map,
    maps?: Map[],
    mapProperties?: Map,
    markerCategories: MarkerCategory[],
    selectedMarkerCategories: string[],
    isMarkerCategoriesDrawerOpened: boolean,
    openedMarker?: Marker,
    searchResult?: Marker,
    defaultMapViewport: MapViewport,
    isMarkerEditionModeEnabled: boolean,
    isMapListOpened: boolean,
    toggleMapListDrawer: () => void,
    closeMapListDrawer: () => void ,
    openMapCreationDialog: () => void,
    openMapEditionDialog: () => void,
    closeMapDialog: () => void,
    toggleSelectedMarkerCategories: (markerCategories: string[]) => void,
    openMarkerCategoryFiltersDrawer: () => void,
    closeMarkerCategoryFiltersDrawer: () => void,
    openMarkerDrawer: (marker?: Marker) => void,
    addMarkerManually: (marker: Marker) => void,
    isDraggableMarker: (marker: Marker) => boolean,
    closeMarkerDrawer: () => void,
    openSearchResultDrawer: (marker: Marker) => void,
    enableMarkerEdition: () => void,
    moveMarker: (marker: Marker, newLatitude: number, newLongitude: number) => void,
    isMarkerDrawerOpened: () => boolean,
    saveMaker: (marker: Marker) => void,
    deleteMarker: () => void,
    addAssetToMaker: (selectedAssets: string[]) => void,
    cancelMarkerEdition: () => void,
    saveMap: (map: Map) => void,
    getMap: (mapId: string) => void,
    getMapAccessibleThroughLink: (mapId: string) => void,
    getCartographerMaps: () => void,
    isMapControlsHidden: (isMobileDevice: boolean) => boolean,
    isMarkerCategoryFiltersButtonDisplayed: (isMobileDevice: boolean) => boolean,
}>({
    map: Map.create(),
    maps: [],
    mapProperties: undefined,
    markerCategories: [],
    selectedMarkerCategories: [],
    isMarkerCategoriesDrawerOpened: false,
    openedMarker: undefined,
    searchResult: undefined,
    defaultMapViewport: {width: 1, height: 1},
    isMarkerEditionModeEnabled: false,
    isMapListOpened: false,
    toggleMapListDrawer: () => {},
    closeMapListDrawer: () => {},
    openMapCreationDialog: () => {},
    openMapEditionDialog: () => {},
    closeMapDialog: () => {},
    toggleSelectedMarkerCategories: () => [],
    openMarkerCategoryFiltersDrawer: () => {},
    closeMarkerCategoryFiltersDrawer: () => {},
    addMarkerManually: () => {},
    openMarkerDrawer: () => {},
    isDraggableMarker: () => false,
    closeMarkerDrawer: () => {},
    openSearchResultDrawer: () => {},
    enableMarkerEdition: () => {},
    moveMarker: () => {},
    isMarkerDrawerOpened: () => false,
    saveMaker: () => {},
    deleteMarker: () => {},
    addAssetToMaker: () => {},
    cancelMarkerEdition: () => {},
    saveMap: () => {},
    getMap: () => {},
    getMapAccessibleThroughLink: () => {},
    getCartographerMaps: () => {},
    isMapControlsHidden: () => false,
    isMarkerCategoryFiltersButtonDisplayed: () => false,
});

export const InteractiveMapProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const {notification, ...openMapHook} = useInteractiveMap();

    if (!openMapHook.map) {
        return null;
    }

    return (
        <InteractiveMapContext.Provider value={{...openMapHook}}>
            {children}
            <NotificationBar notification={notification}/>
        </InteractiveMapContext.Provider>
    );
};