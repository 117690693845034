import {SearchResult, SearchResultProperties} from "./search-result";
import {LngLat, LngLatBounds} from "mapbox-gl";
import {MarkerCategory} from "../marker-category/marker-category";
import {AssetCollection} from "../map-gallery/asset";

type AssetState = {
    assetId: string,
    preview: string,
    thumbnail: string,
    width: number,
    height: number,
}

export type MarkerState = {
    markerId: string,
    mapboxId: string,
    latitude: number,
    longitude: number,
    name: string,
    address: string,
    description: string,
    link: string,
    categories: string[],
    hideOnMapAccessibleThroughLink: boolean,
    addedAt: string,
    markerGallery: AssetState[],
}

export type Icon = {
    color: string,
    icon: string,
}

export class Marker {
    constructor( private readonly state: MarkerState) {}

    public static fromSearchResult(searchResult: SearchResult): Marker {
        const searchResultProperties = new SearchResultProperties(searchResult.properties);

        return new Marker({
            markerId: '',
            mapboxId: searchResult.id,
            latitude: searchResult.center[1],
            longitude: searchResult.center[0],
            name: searchResult.text,
            address: searchResult.place_name,
            description: '',
            link: '',
            categories: searchResultProperties.extractCategories(),
            hideOnMapAccessibleThroughLink: false,
            addedAt: '',
            markerGallery: [],
        });
    }

    public static fromLocation(latitude: number, longitude: number): Marker {
        return new Marker({
            markerId: '',
            mapboxId: '',
            latitude: latitude,
            longitude: longitude,
            name: '',
            address: '',
            description: '',
            link: '',
            categories: [],
            hideOnMapAccessibleThroughLink: false,
            addedAt: '',
            markerGallery: [],
        });
    }

    public markerId(): string {
        return this.state.markerId;
    }

    public latitude(): number {
        return this.state.latitude;
    }

    public longitude(): number {
        return this.state.longitude;
    }

    public name(): string {
        return this.state.name;
    }

    public address(): string {
        return this.state.address;
    }

    public description(): string {
        return this.state.description;
    }

    public link(): string {
        return this.state.link;
    }

    public categories(): string[] {
        return this.state.categories;
    }

    public hideOnMapAccessibleThroughLink(): boolean {
        return this.state.hideOnMapAccessibleThroughLink;
    }

    public markerGallery(): AssetCollection {
        return new AssetCollection(this.state.markerGallery);
    }

    public markerGalleryAssetId(): string[] {
        return this.state.markerGallery.map((asset: AssetState) => asset.assetId);
    }

    public isAddedToTheMap(): boolean {
        return this.state.markerId !== '';
    }

    public isIdentifiedBy(markerId?: string): boolean {
        return markerId === this.state.markerId;
    }

    public editMarker(key: string, value: string|MarkerCategory[]|boolean): Marker {
        let propertyValue;
        if (key === 'categories') {
            propertyValue = (value as MarkerCategory[]).map((category: MarkerCategory): string => category.code);
        } else {
            propertyValue = value
        }

        return new Marker({
            ...this.state,
            ...{[key]: propertyValue}
        });
    }

    public move(latitude: number, longitude: number): Marker {
        return new Marker({
            ...this.state,
            ...{latitude, longitude},
        });
    }

    public toDataToSubmit(): {
        markerId: string,
        mapboxId: string,
        latitude: number,
        longitude: number,
        name: string
        address: string
        description: string
        link: string
        categories: string[],
        hideOnMapAccessibleThroughLink: boolean,
    } {
        const toDataToSubmit = {
            ...this.state,
        };

        delete toDataToSubmit.addedAt;
        delete toDataToSubmit.markerGallery;

        return toDataToSubmit;
    }

    public toLngLatBounds(): LngLatBounds {
        return new LngLatBounds(
            [this.state.longitude, this.state.latitude],
            [this.state.longitude, this.state.latitude]
        );
    }

    public toLngLat(): LngLat {
        return new LngLat(this.state.longitude, this.state.latitude);
    }

    public addedAt(): Date {
        return new Date(this.state.addedAt);
    }

    public icon(markerCategories: MarkerCategory[]): Icon {
        for(const categoryCode in this.state.categories){
            const markerCategory = markerCategories.filter(
                (category: MarkerCategory) => category.code === this.state.categories[categoryCode] && category.icon !== ''
            ).shift();

            if(markerCategory) {
                return {
                    icon: markerCategory.icon,
                    color: markerCategory.iconColor,
                };
            }
        }

        return {
            icon: 'marker',
            color: '#2196F3',
        };
    }
}
