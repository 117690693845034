import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {FormattedMessage} from "react-intl";
import {Alert, Box, Button, ButtonGroup} from '@mui/material';
import {AddAPhoto, ArrowBackIos, Refresh} from '@mui/icons-material';
import {MapGallery} from "../../domain/map-gallery/map-gallery";
import * as usecase from "../../services/map-gallery";
import MapListDrawer, {useMapListDrawer} from "../component/map/MapListDrawer";
import AssetUploaderDialog from "../component/map-gallery/AssetUploader";
import AssetGallery from "../component/map-gallery/AssetGallery";
import {PageLayout} from "../../../common";

export const useMapGallery = (mapId: string, defaultIsAssetsDialogOpened: boolean = false) => {
    const navigate = useNavigate();
    const [isAssetsDialogOpened, setIsAssetsDialogOpened] = useState<boolean>(defaultIsAssetsDialogOpened);
    const [mapGallery, setMapGallery] = useState<MapGallery>();

    const getMapGallery = async () => {
        setMapGallery(await usecase.openMapGallery(mapId));
    };

    const closeAddAssetsDialog = async () => {
        setMapGallery(await usecase.openMapGallery(mapId));
        setIsAssetsDialogOpened(false);
    };

    const openAddAssetsDialog = (): void => {
        setIsAssetsDialogOpened(true);
    };

    const goBackToMapBoard = (): void => {
        navigate(`/maps/${mapId}`)
    };

    return {
        mapGallery,
        isAssetsDialogOpened,
        apiUrl: `/api/maps/${mapId}/gallery`,
        openMapGalleryToAssociateAssetsToMarker: getMapGallery,
        closeAddAssetsDialog,
        openAddAssetsDialog,
        goBackToMapBoard,
    }
}

const MapGalleryPage: React.FC = () => {
    const { mapId } = useParams<{ mapId: string }>();
    const {
        mapGallery,
        isAssetsDialogOpened,
        getMapGallery,
        closeAddAssetsDialog,
        openAddAssetsDialog,
        apiUrl,
        goBackToMapBoard,
    } = useMapGallery(mapId!);

    const {
        maps,
        isMapListDrawerOpened,
        getCartographerMaps,
        toggleMapListDrawer,
    } = useMapListDrawer()

    useEffect(() => {
        (async () => {
            await getMapGallery();
            await getCartographerMaps();
        })();
    }, []);

    return (
        <PageLayout
            title={mapGallery?.mapName}
            onOpenMenu={toggleMapListDrawer}
        >
            <MapListDrawer
                maps={maps}
                isDrawerOpened={isMapListDrawerOpened}
                onDrawerClose={toggleMapListDrawer}
            />

            <Box textAlign="right" margin="20px 5px">
                <ButtonGroup>
                    <Button
                        variant="contained"
                        startIcon={<ArrowBackIos />}
                        color="inherit"
                        onClick={goBackToMapBoard}>
                        <FormattedMessage id="cartography.backToTheMap" />
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<Refresh />}
                        color="info"
                        onClick={getMapGallery}>
                        <FormattedMessage id="cartography.refreshGallery" />
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddAPhoto />}
                        onClick={openAddAssetsDialog}
                    >
                        <FormattedMessage id="cartography.addAssets" />
                    </Button>
                </ButtonGroup>
            </Box>

            {isAssetsDialogOpened &&
                <AssetUploaderDialog
                    closeAddAssetsDialog={closeAddAssetsDialog}
                    apiUrl={apiUrl}
                />
            }

            {mapGallery &&
                <Box maxHeight="calc(100% - 78px)" overflow="auto">
                    <AssetGallery
                        assetCollection={mapGallery.assets}
                        emptyGalleryMessage={
                            <Alert severity="info">
                                <FormattedMessage id="cartography.assetsGalleryEmpty" />
                            </Alert>
                        }
                    />
                </Box>
            }
        </PageLayout>
    );
}

export default MapGalleryPage;

