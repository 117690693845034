import HttpClient from "../../../application/http/HttpClient";
import {Cartographer, Profile} from "../domain/cartographer/cartographer";

const httpClient: HttpClient = new HttpClient();

export const getProfile = async (): Promise<Profile> => {
    const response = await httpClient.get(`/cartographers/profile`);

    return response.data;
}

export const askForAllCartographers = async (): Promise<Cartographer[]> => {
    const response = await httpClient.get(`/cartographers`);

    return response.data;
}
