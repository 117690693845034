import React, {ChangeEvent, ReactElement, useContext, useEffect, useState} from "react";
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    styled,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import {ChevronLeft, FilterList} from "@mui/icons-material";
import {MarkerIcon, MenuDrawer} from "../../../../common";
import {FormattedMessage, useIntl} from "react-intl";
import {MarkerCategory} from "../../../domain/marker-category/marker-category";
import {InteractiveMapContext} from "../map/InteractiveMapContext";

const DrawerTitle = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    minHeight: 64,
    justifyContent: 'flex-end',
}));

const OpenDrawerButton = styled(IconButton)(({ theme }) => ({
    position: 'fixed',
    top: 73,
    left: 10,
    padding: '6px 3px',
    zIndex: 1000,
    backgroundColor: '#FFFFFF',
    border: '1px solid #C0C0C0',
    borderRadius: 6,
    [theme.breakpoints.down('sm')]: {
        top: 66,
        left: 10,
        padding: '12px 7px',
    },
    '&:hover': {
        backgroundColor: '#F0F0F0',
    }
}));

export const OpenMarkerCategoryFilterDrawerButton: React.FC = () => {
    const context = useContext(InteractiveMapContext);

    return (
        <OpenDrawerButton
            data-testid="open-drawer"
            onClick={context.openMarkerCategoryFiltersDrawer}
        >
            <FilterList />
        </OpenDrawerButton>
    );
};

const MarkerCategoryFilterDrawer: React.FC = () => {
    const {formatMessage, locale} = useIntl();
    const context = useContext(InteractiveMapContext);
    const [areMarkerCategoriesSelected, setAreMarkerCategoriesSelected] = useState(true);
    const [filterQueryString, setFilterQueryString] = useState('');

    useEffect(() => {
        if (context.selectedMarkerCategories.length !== context.map.markerCategories().length) {
            setAreMarkerCategoriesSelected(false);
        } else {
            setAreMarkerCategoriesSelected(true);
        }
    }, [context.selectedMarkerCategories, context.map]);

    const handleToggle = (value: string) => () => {
        const currentIndex = context.selectedMarkerCategories.indexOf(value);
        const newChecked = [...context.selectedMarkerCategories];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        context.toggleSelectedMarkerCategories(newChecked);
    };

    const toggleMarkerSelection = (isChecked: boolean) => {
        !isChecked ? context.toggleSelectedMarkerCategories([]) : context.toggleSelectedMarkerCategories(context.map.markerCategories());
        setAreMarkerCategoriesSelected(isChecked);
    };

    return (
        <MenuDrawer
            open={context.isMarkerCategoriesDrawerOpened}
            onClose={() => {}} onOpen={() => {}} // todo: migration
        >
            <DrawerHeader>
                <DrawerTitle>
                    <FormattedMessage id="cartography.markerCategoryList" />
                </DrawerTitle>
                <IconButton
                    data-testid="close-drawer"
                    onClick={context.closeMarkerCategoryFiltersDrawer}
                    size="large">
                    <ChevronLeft/>
                </IconButton>
            </DrawerHeader>

            <Divider/>

            <List>
                <ListItem key="all">
                    <ListItemText>
                        <TextField
                            margin="dense"
                            inputProps={{ "data-testid": "filter-marker-category" }}
                            variant="outlined"
                            label={formatMessage({id: 'cartography.filterMarkerCategory'})}
                            fullWidth
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setFilterQueryString(e.target.value)}
                            value={filterQueryString}
                        />
                    </ListItemText>
                    <Switch
                        edge="end"
                        data-testid={`toggle-marker-categories-selection`}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => toggleMarkerSelection(event.target.checked)}
                        checked={areMarkerCategoriesSelected}
                    />
                </ListItem>
                {
                    context.markerCategories
                        .filter((category: MarkerCategory) => context.map.markerCategories().includes(category.code))
                        .filter((category: MarkerCategory) => {
                            if (filterQueryString === '') {
                                return true;
                            }

                            return category.label(locale).toLowerCase().includes(filterQueryString.toLowerCase());
                        })
                        .map((category: MarkerCategory): ReactElement =>
                            <ListItem key={category.code}>
                                <ListItemIcon>
                                    <MarkerIcon icon={category?.icon||''}/>
                                </ListItemIcon>
                                <ListItemText primary={category?.label(locale)||category.code} />
                                <Switch
                                    edge="end"
                                    data-testid={`switch-${category.code}`}
                                    onChange={handleToggle(category.code)}
                                    checked={context.selectedMarkerCategories.indexOf(category.code) !== -1}
                                />
                            </ListItem>
                        )
                }
            </List>
        </MenuDrawer>
    );
};

export default MarkerCategoryFilterDrawer;