import React, {ReactNode, useCallback, useState} from "react";
import {Box, IconButton, styled} from "@mui/material";
import {ZoomIn} from "@mui/icons-material";
import ThumbnailGallery from "react-photo-gallery";
import Lightbox from "react-image-lightbox";
import {AssetCollection} from "../../../domain/map-gallery/asset";
import {grey} from '@mui/material/colors';
import 'react-image-lightbox/style.css';

const CheckedCheckmarkSvg = styled((props) => (
    <svg {...props}>
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
))(() => ({
    fill: "#06befa",
    width: 22,
    height: 22,
    margin: '-1px',
}));

const Checkmark: React.FC<{selected: boolean, onClick: () => void}> = ({ selected, onClick }) => (
    <Box
        sx={{
            left: 4,
            top: 4,
            position: "absolute",
            zIndex: 1,
            border: !selected ? "2px solid #FFF" : "none",
            borderRadius: "50%",
            backgroundColor: !selected ? "none" : "#FFF",
            width: 22,
            height: 22
        }}
        onClick={onClick}
    >
        {selected && <CheckedCheckmarkSvg />}
    </Box>
);

type Asset = { title: string, width: number, height: number };

const AssetThumbnail: React.FC<{
    asset: Asset,
    isSelected: boolean,
    onAssetSelect: () => void,
    onAssetUnselect: () => void,
    onAssetZoom: () => void,
}> = ({
    asset,
    isSelected,
    onAssetSelect,
    onAssetUnselect,
    onAssetZoom,
}) => {
    let transform = ''
    if (isSelected) {
        //calculate x,y scale
        const sx = (100 - (30 / asset.width) * 100) / 100;
        const sy = (100 - (30 / asset.height) * 100) / 100;
        transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;
    }

    const onAssetClick = isSelected ? onAssetUnselect : onAssetSelect;

    return (
        <Box sx={{
            backgroundColor: "#eee",
            cursor: "pointer",
            overflow: "hidden",
            position: "relative",
            margin: '2px',
            height: asset.height,
            width: asset.width
        }}>
            <Checkmark
                selected={isSelected}
                onClick={onAssetClick}
            />
            <img
                alt={asset.title}
                style={{
                    transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
                    transform
                }}
                {...asset}
                onClick={onAssetClick}
            />
            <IconButton
                onClick={onAssetZoom}
                color="default"
                sx={{
                    right: 0,
                    bottom: 0,
                    zIndex: 1000,
                    position: 'absolute',
                }}
            >
                <ZoomIn
                    color="secondary"
                    fontSize="large"
                    sx={{
                        color: grey[50],
                    }}
                />
            </IconButton>
        </Box>
    );
};

const PreviewLightbox: React.FC<{
    assetCollection: AssetCollection,
    selectedAssetIndex: number,
    onNextAssetMove: () => void,
    onPreviousAssetMove: () => void,
    onClose: () => void,
}> = ({
    assetCollection,
    selectedAssetIndex,
    onNextAssetMove,
    onPreviousAssetMove,
    onClose,
}) => {
    return (
        <Lightbox
            mainSrc={assetCollection.previews(selectedAssetIndex)}
            nextSrc={assetCollection.previews(selectedAssetIndex + 1)}
            prevSrc={assetCollection.previews(selectedAssetIndex - 1)}
            onCloseRequest={onClose}
            reactModalStyle={{overlay: {zIndex: 3000}}}
            onMovePrevRequest={onPreviousAssetMove}
            onMoveNextRequest={onNextAssetMove}
        />
    );
};

const ThumbnailGrid: React.FC<{
    assetCollection: AssetCollection,
    enableAssetSelection: boolean,
    selectedAssets: string[],
    onAssetClick?: (assetId: number) => void,
    onAssetSelect?: (assetId: string) => void,
    onAssetUnselect?: (assetId: string) => void,
}> = ({
    assetCollection,
    enableAssetSelection,
    selectedAssets,
    onAssetClick,
    onAssetSelect,
    onAssetUnselect,
}) => {
    const renderSelectableAsset = useCallback(
        ({ key, photo, index }) => (
            <AssetThumbnail
                isSelected={selectedAssets.includes(key)}
                key={key}
                asset={photo}
                onAssetSelect={() => onAssetSelect && onAssetSelect(key)}
                onAssetUnselect={() => onAssetUnselect && onAssetUnselect(key)}
                onAssetZoom={() => onAssetClick && onAssetClick(index)}
            />
        ),
        [enableAssetSelection, onAssetUnselect, onAssetSelect, selectedAssets]
    );

    return (
        <ThumbnailGallery
            photos={assetCollection.thumbnails()}
            onClick={(event, {index}: {index: number}) => onAssetClick && onAssetClick(index)}
            renderImage={enableAssetSelection ? renderSelectableAsset : null}
        />
    );
};

/** @internal */
export const useAssetGallery = ({defaultIsAssetLightboxOpened = false, defaultAssetIndex = 0} = {}) => {
    const [isAssetLightboxOpened, setIsAssetLightboxOpened] = useState(defaultIsAssetLightboxOpened);
    const [assetIndex, setAssetIndex] = useState<number>(defaultAssetIndex);

    const openAssetLightbox = (assetIndex: number) => {
        setIsAssetLightboxOpened(true);
        setAssetIndex(assetIndex);
    }

    const closeAssetLightbox = () => {
        setIsAssetLightboxOpened(false);
        setAssetIndex(0);
    }

    const moveNextAssetIndex = () => {
        setAssetIndex(assetIndex + 1);
    }

    const movePreviousAssetIndex = () => {
        setAssetIndex(assetIndex - 1);
    }

    return {
        isAssetLightboxOpened,
        assetIndex,
        openAssetLightbox,
        closeAssetLightbox,
        moveNextAssetIndex,
        movePreviousAssetIndex
    }
}

/** @container */
const AssetGallery: React.FC<{
    assetCollection: AssetCollection,
    emptyGalleryMessage?: ReactNode,
    enableAssetSelection?: boolean,
    selectedAssets?: string[],
    onAssetSelect?: (assetId: string) => void,
    onAssetUnselect?: (assetId: string) => void,
}> = ({
    assetCollection,
    emptyGalleryMessage,
    enableAssetSelection,
    selectedAssets,
    onAssetUnselect,
    onAssetSelect,
}) => {
    const {
        isAssetLightboxOpened,
        assetIndex,
        openAssetLightbox,
        closeAssetLightbox,
        moveNextAssetIndex,
        movePreviousAssetIndex
    } = useAssetGallery();

    return (
        <>
            {
                isAssetLightboxOpened &&
                <PreviewLightbox
                    assetCollection={assetCollection}
                    selectedAssetIndex={assetIndex}
                    onNextAssetMove={moveNextAssetIndex}
                    onPreviousAssetMove={movePreviousAssetIndex}
                    onClose={closeAssetLightbox}
                />
            }

            {assetCollection.isEmpty() && emptyGalleryMessage}

            <ThumbnailGrid
                assetCollection={assetCollection}
                enableAssetSelection={enableAssetSelection}
                onAssetClick={openAssetLightbox}
                onAssetSelect={onAssetSelect}
                onAssetUnselect={onAssetUnselect}
                selectedAssets={selectedAssets||[]}
            />
        </>
    )
};

export default AssetGallery;
