import React, {useContext, useEffect} from 'react';
import {CreateMapAction} from "../component/map/MapActions";
import MapListDrawer from "../component/map/MapListDrawer";
import {MapWithoutMarker} from "../component/map/InteractiveMap";
import MapDialog from "../component/map/MapDialog";
import {PageLayout} from "../../../common";
import {InteractiveMapContext} from "../component/map/InteractiveMapContext";

const LandingPage: React.FC = () => {
    const context = useContext(InteractiveMapContext);

    useEffect(() => {
        context.getCartographerMaps()
    }, []);

    return (
        <PageLayout onOpenMenu={context.toggleMapListDrawer}>
            <MapListDrawer
                maps={context.maps}
                isDrawerOpened={context.isMapListOpened}
                onCreateMapButtonClick={context.openMapCreationDialog}
                onDrawerClose={context.closeMapListDrawer}
            />

            <MapWithoutMarker />

            <CreateMapAction onClick={context.openMapCreationDialog} />

            {context.mapProperties &&
                <MapDialog
                    map={context.mapProperties}
                    onFormSubmission={context.saveMap}
                    onClose={context.closeMapDialog}
                />
            }
        </PageLayout>
    );
}

export default LandingPage;


