import React, {ReactNode} from "react";
import {Marker} from "../../../../domain/map/marker";
import {Map} from "../../../../domain/map/map";
import {MarkerCategory} from "../../../../domain/marker-category/marker-category";
import {FormattedMessage, useIntl} from "react-intl";
import {toast} from "react-toastify";
import {Box, Button, Chip, Divider, List, ListItem, ListItemIcon, ListItemText, styled} from "@mui/material";
import {AddAPhoto, Delete, Description, Edit, Link, Public, Room, VpnLock} from "@mui/icons-material";
import {MarkerIcon} from "../../../../../common";
import CopyToClipboard from "react-copy-to-clipboard";
import {MarkerTitle} from "./MarkerTitle";

const MarkerActionButton = styled(Button)(({theme}) => ({
    margin: theme.spacing(0.5, 0),
}));

const CopyTextToClipboard = styled(CopyToClipboard)(({theme}) => ({
    cursor: 'grab',
}));

const MarkerPropertyIcon = styled(ListItemIcon)(() => ({
    minWidth: 34,
}));

export const MarkerActions: React.FC<{
    isPremiumCartographer: boolean,
    onDeleteMarker: () => void,
    onEnableEditionForm: () => void,
    onAssetDialogOpen: () => void,
}> = ({
    isPremiumCartographer, onDeleteMarker, onEnableEditionForm, onAssetDialogOpen
}) => {
    return (
        <Box data-testid="marker-action" sx={{
            textAlign: 'center',
            paddingY: 1,
        }}>
            <MarkerActionButton
                color="primary"
                variant="contained"
                onClick={onDeleteMarker}
                startIcon={<Delete/>}
            >
                <FormattedMessage id="delete"/>
            </MarkerActionButton>
            {' '}
            <MarkerActionButton
                color="primary"
                variant="contained"
                onClick={onEnableEditionForm}
                startIcon={<Edit/>}
            >
                <FormattedMessage id="edit"/>
            </MarkerActionButton>
            {' '}
            {
                isPremiumCartographer &&
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onAssetDialogOpen}
                    startIcon={<AddAPhoto />}
                >
                    <FormattedMessage id="cartography.addAssets" />
                </Button>
            }
        </Box>
    );
};

export const MarkerVisibility: React.FC<{
    map: Map,
    marker: Marker,
}> = ({
    map, marker,
}) => {
    if (!map.accessibleThroughLink()) {
        return null;
    }

    return (
        <ListItem disableGutters data-testid="marker-visibility">
        {
            marker.hideOnMapAccessibleThroughLink() ?
            <Chip
                icon={<VpnLock/>}
                label={<FormattedMessage id="cartography.hiddenOnMapAccessibleThroughLink" />}
                color="primary"
                variant="outlined"
            /> :
            <Chip
                icon={<Public/>}
                label={<FormattedMessage id="cartography.displayedOnMapAccessibleThroughLink" />}
                color="secondary"
                variant="outlined"
            />
        }
        </ListItem>
    );
};

export const MarkerDetailHeader: React.FC<{
    marker: Marker,
    markerCategories: MarkerCategory[],
}> = ({
    marker,
    markerCategories,
}) => {
    return (
        <>
            <MarkerTitle icon={marker.icon(markerCategories).icon}>
                <CopyTextToClipboard
                    text={marker.name()}
                    onCopy={() => toast.success(<FormattedMessage id="copied" />)}
                >
                    <span>{marker.name()}</span>
                </CopyTextToClipboard>
            </MarkerTitle>
            <Divider/>
        </>
    );
};

export const MarkerDetailProperties: React.FC<{
    marker: Marker,
    markerCategories: MarkerCategory[],
    children?: ReactNode
}> = ({
    marker,
    markerCategories,
    children
}) => {
    const {locale} = useIntl();
    const getMarkerCategoryByCode = (categoryCode: string): MarkerCategory|undefined => {
        return markerCategories.filter((category: MarkerCategory) => category.code === categoryCode).shift();
    };

    return (
        <List dense sx={{paddingX: 1}}>
            {
                <ListItem disableGutters >
                    <Box
                        data-testid="marker-categories"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                            '& > *': {
                                margin: 0.5,
                            },
                        }}
                    >
                    {
                        marker.categories().map((categoryCode: string): ReactNode => (
                            <Chip
                                sx={{margin: 0.5}}
                                data-testid="marker-category"
                                key={categoryCode}
                                icon={<MarkerIcon icon={getMarkerCategoryByCode(categoryCode)?.icon || ''}/>}
                                label={getMarkerCategoryByCode(categoryCode)?.label(locale) || categoryCode}
                                variant="outlined"
                            />
                        ))
                    }
                    </Box>
                </ListItem>
            }
            {
                marker.address() &&
                <ListItem disableGutters data-testid="marker-address">
                    <MarkerPropertyIcon>
                        <Room/>
                    </MarkerPropertyIcon>
                    <CopyTextToClipboard
                        text={marker.address()}
                        onCopy={() => toast.success(<FormattedMessage id="copied" />)}
                    >
                        <ListItemText primary={marker.address()}/>
                    </CopyTextToClipboard>
                </ListItem>
            }
            {
                marker.description() &&
                <ListItem disableGutters data-testid="marker-description" >
                    <MarkerPropertyIcon>
                        <Description/>
                    </MarkerPropertyIcon>
                    <ListItemText primary={marker.description()}/>
                </ListItem>
            }
            {
                marker.link() &&
                <ListItem disableGutters data-testid="marker-link">
                    <MarkerPropertyIcon>
                        <Link/>
                    </MarkerPropertyIcon>
                    <ListItemText primary={<a href={marker.link()}>{marker.link()}</a>}/>
                </ListItem>
            }
            {children}
        </List>
    );
};
