import React from "react";
import {Grid, styled} from "@mui/material";
import {MarkerIcon} from "../../../../../common";

const MarkerTitleContainer = styled("div")(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    [theme.breakpoints.up('xs')]: {
        minHeight: 48
    },
    [theme.breakpoints.up('md')]: {
        minHeight: 64
    },
}));

export const MarkerTitle: React.FC<{ icon: string }> = ({icon, children}) => (
    <MarkerTitleContainer data-testid="marker-title">
        <Grid container alignItems="stretch">
            <Grid item xs={1} style={{textAlign: 'center'}}>
                <MarkerIcon icon={icon}/>
            </Grid>
            <Grid item xs={11}>
                {children}
            </Grid>
        </Grid>
    </MarkerTitleContainer>
);