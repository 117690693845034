import React from 'react';
import {useIntl} from "react-intl";
import {DataGrid, GridRenderEditCellParams, GridValueFormatterParams} from "@mui/x-data-grid";
import {MarkerCategoryDataGridRow} from "./domain/marker-category";
import {MarkerIcon} from "../../common";
import {styled} from "@mui/material";
import {IconSetAutocomplete} from "./IconSetAutocomplete";

const Icon = styled(MarkerIcon)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const MarkerCategoryDataGrid: React.FC<{
    markerCategories: MarkerCategoryDataGridRow[],
    changeIcon: (code: string, icon: string) => void,
}> = ({
    markerCategories,
    changeIcon,
}) => {
    const {formatMessage} = useIntl();

    return (
        <DataGrid
            pagination
            pageSize={25}
            rowsPerPageOptions={[25]}
            columns={[
                {
                    field: 'code',
                    headerName: formatMessage({id: 'code'}),
                    flex: 1,
                },
                {
                    field: 'labelEn',
                    headerName: formatMessage({id: 'labelEn'}),
                    width: 300,
                },
                {
                    field: 'labelFr',
                    headerName: formatMessage({id: 'labelFr'}),
                    width: 300,
                },
                {
                    field: 'icon',
                    width: 300,
                    headerName: formatMessage({id: 'icon'}),
                    disableColumnMenu: true,
                    editable: true,
                    renderCell: (params: GridValueFormatterParams) => (
                        <>
                            <Icon icon={params.value as string} />
                            {params.value}bar
                        </>
                    ),
                    renderEditCell: (params: GridRenderEditCellParams) => (
                        <IconSetAutocomplete
                            value={params.value as string}
                            onChange={async (selectedIcon: string) => {
                                const {id, field, api} = params;
                                await changeIcon(params.getValue(id, 'code') as string, selectedIcon);

                                api.setEditCellValue({ id, field, value: selectedIcon });
                                api.commitCellChange({ id, field });
                                api.setCellMode(id, field, 'view');
                            }}
                        />
                    )
                }
            ]}
            rows={markerCategories}
        />
    );
}

export default MarkerCategoryDataGrid;

