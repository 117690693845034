import React from "react";
import icons from "./marker.svg";

export class IconSet {
    private icons: string[] = [
        'aerialway',
        'airfield',
        'airport',
        'alcohol-shop',
        'american-football',
        'amusement-park',
        'aquarium',
        'art-gallery',
        'attraction',
        'bakery',
        'bank',
        'bar',
        'barrier',
        'baseball',
        'basketball',
        'bbq',
        'beach',
        'beer',
        'bicycle',
        'bicycle-share',
        'blood-bank',
        'bowling-alley',
        'bridge',
        'building',
        'building-alt1',
        'bus',
        'cafe',
        'campsite',
        'car',
        'car-rental',
        'car-repair',
        'casino',
        'castle',
        'cemetery',
        'charging-station',
        'cinema',
        'circle',
        'circle-stroked',
        'city',
        'clothing-store',
        'college',
        'commercial',
        'communications-tower',
        'confectionery',
        'convenience',
        'cricket',
        'cross',
        'dam',
        'danger',
        'defibrillator',
        'dentist',
        'doctor',
        'dog-park',
        'drinking-water',
        'embassy',
        'emergency-phone',
        'entrance',
        'entrance-alt1',
        'farm',
        'fast-food',
        'fence',
        'ferry',
        'fire-station',
        'fitness-centre',
        'florist',
        'fuel',
        'furniture',
        'gaming',
        'garden',
        'garden-centre',
        'gift',
        'globe',
        'golf',
        'grocery',
        'hairdresser',
        'harbor',
        'hardware',
        'heart',
        'heliport',
        'home',
        'horse-riding',
        'hospital',
        'ice-cream',
        'industry',
        'information',
        'jewelry-store',
        'karaoke',
        'landmark',
        'landuse',
        'laundry',
        'library',
        'lighthouse',
        'lodging',
        'logging',
        'marker',
        'marker-stroked',
        'mobile-phone',
        'monument',
        'mountain',
        'museum',
        'music',
        'natural',
        'optician',
        'paint',
        'park',
        'park-alt1',
        'parking',
        'parking-garage',
        'pharmacy',
        'picnic-site',
        'pitch',
        'place-of-worship',
        'playground',
        'police',
        'post',
        'prison',
        'rail',
        'rail-light',
        'rail-metro',
        'ranger-station',
        'recycling',
        'religious-buddhist',
        'religious-christian',
        'religious-jewish',
        'religious-muslim',
        'religious-shinto',
        'residential-community',
        'restaurant',
        'restaurant-noodle',
        'restaurant-pizza',
        'restaurant-seafood',
        'roadblock',
        'rocket',
        'school',
        'scooter',
        'shelter',
        'shoe',
        'shop',
        'skateboard',
        'skiing',
        'slaughterhouse',
        'slipway',
        'snowmobile',
        'soccer',
        'square',
        'square-stroked',
        'stadium',
        'star',
        'star-stroked',
        'suitcase',
        'sushi',
        'swimming',
        'table-tennis',
        'teahouse',
        'telephone',
        'tennis',
        'theatre',
        'toilet',
        'town',
        'town-hall',
        'triangle',
        'triangle-stroked',
        'veterinary',
        'viewpoint',
        'village',
        'volcano',
        'warehouse',
        'waste-basket',
        'watch',
        'water',
        'waterfall',
        'watermill',
        'wetland',
        'wheelchair',
        'windmill',
        'zoo',
    ];

    matchFirstIconCode(icons: string[]): string {
        return icons
            .filter((icon: string) => this.icons.includes(icon))
            .shift() || 'marker';
    }

    belongToIconSet(icon: string): boolean {
        return this.icons.includes(icon);
    }

    toArray(): string[] {
        return this.icons;
    }
}

const iconSet = new IconSet();

const MarkerIcon: React.FC<{
    icon: string,
    color?: string,
    size?: number,
    className?: string,
    onClick?: () => void,
}> = ({
          icon,
          color,
          size = 20,
          className,
          onClick
      }) => {
    if (!iconSet.belongToIconSet(icon)) {
        return null;
    }

    return (
        <svg
            data-testid={`icon-${icon}`}
            className={className}
            style={{
                fill: color,
                width: size,
                height: size,
                stroke: 'none',
            }}
            onClick={onClick}
        >
            <use xlinkHref={`${icons}#${icon}-15`}/>
        </svg>
    );
};

export {
    MarkerIcon,
    iconSet
};