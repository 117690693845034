import React, {useContext} from 'react';
import {BrowserRouter, Route, Navigate, Routes} from 'react-router-dom';
import IntlProvider from './i18n';
import {MapBoard, MapAccessibleThroughLink, MapGallery, LandingPage} from '../cartography/map-board';
import {CreateAccount, Login, AskForResetPassword, ResetPassword} from '../identity';
import {MarkerCategoryList, CartographerList} from '../cartography/admin';
import {ToastContainer} from "react-toastify";
import AuthProvider, {AuthContext} from "./auth";
import {InteractiveMapProvider} from "../cartography/map-board/view/component/map/InteractiveMapContext";

const DefaultPage: React.FC = () => {
    const {profile} = useContext(AuthContext);

    return profile ? <Navigate to="/maps"/> : <Navigate to="/login"/>
};

function App() {
    return (
        <IntlProvider>
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/admin/categories" element={<MarkerCategoryList />}/>
                        <Route path="/admin/cartographers" element={<CartographerList />}/>
                        <Route
                            path="/maps"
                            element={
                                <InteractiveMapProvider>
                                    <LandingPage/>
                                </InteractiveMapProvider>
                            }
                        />
                        <Route
                            path="/maps/:mapId"
                            element={
                                <InteractiveMapProvider>
                                    <MapBoard/>
                                </InteractiveMapProvider>
                            }
                        />
                        <Route
                            path="/maps/:mapId/through-link"
                            element={
                                <InteractiveMapProvider>
                                    <MapAccessibleThroughLink/>
                                </InteractiveMapProvider>
                            }
                        />
                        <Route path="/maps/:mapId/gallery" element={<MapGallery />}/>
                        <Route path="/login" element={<Login />}/>
                        <Route path="/accounts/create" element={<CreateAccount />}/>
                        <Route path="/accounts/reset-password/:token" element={<ResetPassword />}/>
                        <Route path="/accounts/ask-for-reset-password" element={<AskForResetPassword />}/>
                        <Route path="/" element={<DefaultPage />}/>
                    </Routes>
                </BrowserRouter>
                <ToastContainer />
            </AuthProvider>
        </IntlProvider>
    );
}

export default App;
