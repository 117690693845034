import {MarkerCategory, MarkerCategoryState} from "../domain/marker-category/marker-category";
import HttpClient from "../../../application/http/HttpClient";

const httpClient: HttpClient = new HttpClient();

export const askForAllCategories = async (): Promise<MarkerCategory[]> => {
    const response = await httpClient.get(`/categories`);

    return response.data.map(
        (category: MarkerCategoryState): MarkerCategory => new MarkerCategory(category)
    );
}