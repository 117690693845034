import {LngLatBounds} from "mapbox-gl";
import {WebMercatorViewport} from "react-map-gl";
import {Cartographer} from "../cartographer/cartographer";
import {Marker, MarkerState} from "./marker";

// Map

export type MapViewport = {
    width: number;
    height: number;
    latitude?: number;
    longitude?: number;
    position?: number[];
    zoom?: number;
    pitch?: number;
    bearing?: number;
    altitude?: number;
    fovy?: number;
    nearZMultiplier?: number;
    farZMultiplier?: number;
};

export type MapState = {
    mapId: string,
    owner: string,
    name: string,
    description: string,
    cartographersAllowedToEditMap: string[],
    accessibleThroughLink: boolean,
    tag: string,
    markers: MarkerState[],
    markerCategories: string[],
}

export type MarkerCategoryState = {
    code: string,
    icon: string,
}

export class Map {
    private readonly state: {
        mapId: string,
        owner: string,
        name: string,
        description: string,
        cartographersAllowedToEditMap: string[],
        accessibleThroughLink: boolean,
        tag: string,
        markers: Marker[],
        markerCategories: string[],
    };

    constructor(state: {
        mapId: string,
        owner: string,
        name: string,
        description: string,
        cartographersAllowedToEditMap: string[],
        accessibleThroughLink: boolean,
        tag: string,
        markers: Marker[],
        markerCategories: string[],
    }) {
        this.state = state;
    }

    public static create(): Map {
        return new Map({
            mapId: '',
            owner: '',
            name: '',
            description: '',
            cartographersAllowedToEditMap: [],
            accessibleThroughLink: false,
            tag: 'none',
            markers: [],
            markerCategories: [],
        });
    }

    public static fromState(state: MapState): Map {
        const markers: Marker[] = state.markers?.map((marker: MarkerState): Marker => new Marker(marker))||[];

        return new Map({
            ...state,
            ...{
                markers,
            }
        });
    }

    public mapId(): string {
        return this.state.mapId;
    }

    public name(): string {
        return this.state.name;
    }

    public editName(name: string): Map {
        return new Map({
            ...this.state,
            ...{name},
        });
    }

    public tag(): string {
        return this.state.tag;
    }

    public editTag(tag: string): Map {
        return new Map({
            ...this.state,
            ...{tag},
        });
    }

    public description(): string {
        return this.state.description;
    }

    public editDescription(description: string): Map {
        return new Map({
            ...this.state,
            ...{description},
        });
    }

    public accessibleThroughLink(): boolean {
        return this.state.accessibleThroughLink;
    }

    public editAccessibleThroughLink(accessibleThroughLink: boolean): Map {
        return new Map({
            ...this.state,
            ...{accessibleThroughLink},
        });
    }

    public extractCartographersWithout(excludedCartographer?: string): Cartographer[] {
        return [...this.state.cartographersAllowedToEditMap, this.state.owner]
            .filter((cartographer: string) => cartographer !== excludedCartographer)
            .map((cartographer: string): Cartographer => {
                return {
                    username: cartographer,
                }
            }
        );
    }

    public cartographersAllowedToEditMap(): Cartographer[] {
        return this.state.cartographersAllowedToEditMap.map(
            (cartographer: string): Cartographer => {
                return {
                    username: cartographer,
                }
            }
        );
    }

    public isOwnedBy(cartographer?: string): boolean {
        return this.state.owner === cartographer;
    }

    public editCartographersAllowedToEditMap(cartographers: Cartographer[]): Map {
        const cartographer = cartographers.map((cartographer: Cartographer): string => cartographer.username);

        return new Map({
            ...this.state,
            ...{cartographersAllowedToEditMap: cartographer},
        });
    }

    public addMarker(marker: Marker): Map {
        const markers = this.state.markers
            .filter((currentMarker: Marker) => !currentMarker.isIdentifiedBy(marker.markerId()))

        return new Map({
            ...this.state,
            ...{markers: [...markers, marker]}
        });
    }

    public removeSearchResultMarker(): Map {
        const markers = this.state.markers
            .filter((marker: Marker) => !marker.isIdentifiedBy(''));

        return new Map({
            ...this.state,
            ...{markers: markers}
        });
    }

    public markers(): Marker[] {
        return this.state.markers;
    }

    public markersFilteredByCategory(markerCategories: string[] = []): Marker[] {
        return this.state.markers
            .filter((marker) => {
                return marker.categories().some(
                    categoryCode => markerCategories.includes(categoryCode)
                ) || marker.categories().length === 0
            });
    }

    public getMarkerById(markerId: string): Marker {
        const marker = this.state.markers
            .find((marker: Marker): boolean => marker.markerId() === markerId);

        if (!marker) {
            throw new Error(`The marker ${markerId} does not exist`);
        }

        return marker
    }

    public moveMarkerToNewLocation(markerId: string, latitude: number, longitude: number): Map {
        return new Map({
            ...this.state,
            ...{
                markers: this.state.markers.map((marker: Marker) => {
                    if (marker.isIdentifiedBy(markerId)) {
                        return marker.move(latitude, longitude);
                    }

                    return marker;
                })
            }
        });
    }

    public getLastAddedMarker(): Marker | undefined {
        return this.state.markers
            .slice()
            .sort((a: Marker, b: Marker) => b.addedAt().valueOf() - a.addedAt().valueOf())
            .shift();
    }

    public markerCategories(): string[] {
        return this.state.markerCategories;
    }

    public refreshViewport(mapViewPort: MapViewport): MapViewport {
        if (!this.hasMarker()) {
            return {
                ...mapViewPort,
                ...{
                    latitude: 46.2276,
                    longitude: 2.2137,
                    zoom: 5
                }
            }
        }

        const bounds = this.state.markers.reduce(
            (bounds: LngLatBounds, marker: Marker) => bounds.extend(marker.toLngLat()),
            this.state.markers[0].toLngLatBounds()
        );

        const viewport = new WebMercatorViewport(mapViewPort).fitBounds(
            bounds.toArray() as [[number, number], [number, number]],
            {
                padding: 100,
            }
        );

        return {
            longitude: viewport.longitude,
            latitude: viewport.latitude,
            zoom: this.hasOnlyOneMarker() ? 10 : viewport.zoom,
            pitch: viewport.pitch,
            bearing: viewport.bearing,
            altitude: viewport.altitude,
            width: viewport.width,
            height: viewport.height,
        };
    }

    public toDataToSubmit(): {
        mapId: string,
        name: string,
        description: string,
        cartographersAllowedToEditMap: string[],
        accessibleThroughLink: boolean,
        tag: string,
    } {
        return {
            mapId: this.state.mapId,
            name: this.state.name,
            description: this.state.description,
            cartographersAllowedToEditMap: this.state.cartographersAllowedToEditMap,
            accessibleThroughLink: this.state.accessibleThroughLink,
            tag: this.state.tag,
        };
    }

    private hasMarker(): boolean {
        return 0 < this.state.markers.length;
    }

    private hasOnlyOneMarker(): boolean {
        return 1 === this.state.markers.length;
    }
}


