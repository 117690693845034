import React, {ChangeEvent, FormEvent, useState} from 'react';
import {toast} from 'react-toastify';
import {useIntl} from "react-intl";
import {login} from './account/use-cases';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {Link, useNavigate} from "react-router-dom";
import IdentityPage from "./component/IdentityPage";
import {Form, SubmitButton} from "./component/Form";

const Login: React.FC = () => {
    const {formatMessage} = useIntl();
    const navigate = useNavigate();

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const onSubmit = async () => {
        try {
            await login(username, password);
            navigate(`/maps`);
        } catch (e) {
            toast.error(formatMessage({id: 'identity.loginError'}));
        }
    };

    return (
        <IdentityPage title={formatMessage({id: 'login'})}>
            <Form
                noValidate
                onSubmit={(event: FormEvent<HTMLFormElement>) => event.preventDefault()}
            >
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label={formatMessage({id: 'username'})}
                    name="username"
                    autoComplete="username"
                    autoFocus
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                    value={username}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={formatMessage({id: 'password'})}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    value={password}
                />
                <SubmitButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                >
                    {formatMessage({id: 'identity.buttonLogin'})}
                </SubmitButton>
                <Grid container>
                    <Grid item xs>
                        <Link to={'/accounts/ask-for-reset-password'}>
                            {formatMessage({id: 'identity.forgotPassword'})}
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to={'/accounts/create'}>
                            {formatMessage({id: 'identity.noAccountYet'})}
                        </Link>
                    </Grid>
                </Grid>
            </Form>
        </IdentityPage>
    );
}

export default Login;

