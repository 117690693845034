import React, {MouseEvent, useCallback, useContext, useEffect, useMemo, useState,} from "react";
import {FormattedMessage} from "react-intl";
import {MapEvent,} from "react-map-gl";
import {Menu, MenuItem, PopoverPosition, styled} from "@mui/material";
import {MapViewport} from "../../../domain/map/map";
import {Marker} from "../../../domain/map/marker";
import {MapGL, MapGlProps, MapMarker} from "./MapGL";
import {mobileMarkerDrawerHeight} from "./marker/MarkerDrawer";
import {desktopDrawerWidth} from "../../../../common/Drawer";
import {InteractiveMapContext} from "./InteractiveMapContext";

export const MapWithoutMarker: React.FC = () => {
    const [mapViewport, setMapViewport] = useState<MapViewport>({
        width: 500,
        height: 500,
        latitude: 46.2276,
        longitude: 2.2137,
        zoom: 5,
    });

    const handleViewportChange = useCallback(
        (newMapViewport) => setMapViewport(newMapViewport),
        [setMapViewport]
    );

    return (
        <MapGL
            {...mapViewport}
            hideMapGeocoder={false}
            onViewportChange={handleViewportChange}
        />
    )
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    height: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
        marginBottom: 0,
    },
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('sm')]: {
            marginLeft: desktopDrawerWidth,
        },
        [theme.breakpoints.down('md')]: {
            height: `calc(100% - ${mobileMarkerDrawerHeight}px)`,
            marginBottom: mobileMarkerDrawerHeight,
        }
    }),
}));

export const MapWithMarker: React.FC<MapGlProps> = ({
    children,
    ...props
}) => {
    const context = useContext(InteractiveMapContext);
    const [mapViewport, setMapViewport] = useState<MapViewport>();
    const handleViewportChange = useCallback(
        (newMapViewport) => setMapViewport({
            ...newMapViewport,
            ...{transitionDuration: 0}
        }),
        []
    );

    useEffect(() => {
        handleViewportChange(context.defaultMapViewport);
    }, [context.defaultMapViewport, handleViewportChange]);

    const markers = useMemo(() => context.map.markersFilteredByCategory(context.selectedMarkerCategories).map(
        (marker: Marker) => (
            <MapMarker
                key={marker.markerId()}
                marker={marker}
                onClick={context.openMarkerDrawer}
                draggable={context.isDraggableMarker(marker)}
                onDraggedToNewLocation={context.moveMarker}
            />
        )
    ), [context]);

    return (
        <Main open={context.isMarkerDrawerOpened() || context.isMarkerCategoriesDrawerOpened}>
            <MapGL
                {...mapViewport}
                {...props}
                searchResult={context.searchResult}
                onViewportChange={handleViewportChange}
                onSearchResultSelect={context.openSearchResultDrawer}
                onSearchResultClear={context.closeMarkerDrawer}
                onSearchResultDrag={context.moveMarker}
                handleGeocoderViewportChange={handleViewportChange}
            >
                {children}
                {markers}
            </MapGL>
        </Main>
    )
}

type MarkerPosition = { latitude: number, longitude: number };

export const InteractiveMap: React.FC<MapGlProps> = ({
    ...props
}) => {
    const context = useContext(InteractiveMapContext);
    const [rightClickMenuPosition, setRightClickMenuPosition] = useState<PopoverPosition>();
    const [markerPosition, setMarkerPosition] = useState<MarkerPosition>();

    const openRightClickMenu = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setRightClickMenuPosition({
            top: event.clientY - 4,
            left: event.clientX - 2,
        });
    };

    const closeRightClickMenu = () => {
        setRightClickMenuPosition(undefined);
        setMarkerPosition(undefined);
    };

    const addMarkerManually = (markerPosition: MarkerPosition) => {
        context.addMarkerManually(Marker.fromLocation(markerPosition.latitude, markerPosition.longitude))
        closeRightClickMenu();
    };

    return (
        <span
            onContextMenu={openRightClickMenu}
            onClick={() => setRightClickMenuPosition(undefined)}
            data-testid="interactive-map-container"
        >
            <Menu
                keepMounted
                open={Boolean(rightClickMenuPosition)}
                anchorReference="anchorPosition"
                anchorPosition={rightClickMenuPosition}
            >
                <MenuItem onClick={() => markerPosition && addMarkerManually(markerPosition)}>
                    <FormattedMessage id="cartography.addMarker" />
                </MenuItem>
            </Menu>

            <MapWithMarker
                {...props}
                onClick={(event: MapEvent) => {
                    if (event.rightButton) {
                        setMarkerPosition({latitude: event.lngLat[1], longitude: event.lngLat[0]});
                    }
                }}
            >
            </MapWithMarker>
        </span>
    );
};
