import React, {ChangeEvent, FormEvent, useState} from 'react';
import {toast} from 'react-toastify';
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {createNewAccount} from "./account/use-cases";
import TextField from '@mui/material/TextField';
import IdentityPage from "./component/IdentityPage";
import {Form, SubmitButton} from "./component/Form";

const CreateAccount: React.FC = () => {
    const {formatMessage} = useIntl();
    const navigate = useNavigate();

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [formErrors, setFormErrors] = useState<{ [key:string]:string }>();

    const onSubmit = async () => {
        try {
            await createNewAccount(username, password, passwordConfirm, email);
            toast.success(formatMessage({id: 'identity.accountHaveBeenCreated'}));
            navigate(`/login`);
        } catch (e) {
            const response = e.response;
            if (400 === response.status) {
                setFormErrors(response.data.errors);
            } else {
                toast.error(formatMessage({id: 'formSubmissionError'}));
            }
        }
    };

    return (
        <IdentityPage title={formatMessage({id: 'identity.createAccount'})}>
            <Form
                noValidate
                onSubmit={(event: FormEvent<HTMLFormElement>) => event.preventDefault()}
            >
                <TextField
                    error={Boolean(formErrors?.username)}
                    helperText={formErrors?.username}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label={formatMessage({id: 'username'})}
                    name="username"
                    autoComplete="username"
                    autoFocus
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                    value={username}
                />
                <TextField
                    error={Boolean(formErrors?.email)}
                    helperText={formErrors?.email}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={formatMessage({id: 'email'})}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    value={email}
                />
                <TextField
                    error={Boolean(formErrors?.password)}
                    helperText={formErrors?.password}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={formatMessage({id: 'password'})}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    value={password}
                />
                <TextField
                    error={Boolean(formErrors?.secondPassword)}
                    helperText={formErrors?.secondPassword}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="secondPassword"
                    label={formatMessage({id: 'secondPassword'})}
                    type="password"
                    id="secondPassword"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPasswordConfirm(e.target.value)}
                    value={passwordConfirm}
                />
                <SubmitButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                >
                    {formatMessage({id: 'identity.buttonCreateAccount'})}
                </SubmitButton>
            </Form>
        </IdentityPage>
    );
}

export default CreateAccount;

