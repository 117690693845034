import HttpClient from "../../../application/http/HttpClient";
import {Map, MapState} from "../domain/map/map";
import {Marker} from "../domain/map/marker";

const httpClient: HttpClient = new HttpClient();

export const getMap = async (mapId: string): Promise<Map> => {
    const response = await httpClient.get(`/maps/${mapId}`);

    return Map.fromState(response.data);
}

export const getMapAccessibleThroughLink = async (mapId: string): Promise<Map> => {
    const response = await httpClient.get(`/maps/${mapId}/through-link`);

    return Map.fromState(response.data);
}

export const getMapList = async (): Promise<Map[]> => {
    const response = await httpClient.get(`/maps`);

    return response.data.map((map: MapState) => Map.fromState(map));
}

export const createNewMap = async (map: Map): Promise<Map> => {
    const response = await httpClient.post(`/maps`, map.toDataToSubmit());

    return Map.fromState(response.data);
}

export const editMap = async (map: Map): Promise<Map> => {
    const response = await httpClient.post(`/maps/edit-map`, map.toDataToSubmit());

    return Map.fromState(response.data);
}

export const addMarkerToMap = async (mapId: string, marker: Marker): Promise<Map> => {
    const response = await httpClient.post(`/maps/add-markers`, {
        mapId,
        ...marker.toDataToSubmit(),
    });

    return Map.fromState(response.data);
}

export const editMarkerOnMap = async (mapId: string, marker: Marker): Promise<Map> => {
    const response = await httpClient.post(`/maps/edit-markers`, {
        mapId,
        ...marker.toDataToSubmit(),
    });

    return Map.fromState(response.data);
}

export const deleteMarkerFromMap = async (mapId: string, marker: Marker): Promise<Map> => {
    const response = await httpClient.delete(`/maps/${mapId}/markers/${marker.markerId()}`);

    return Map.fromState(response.data);
}

export const associateAssetsToMarker = async (mapId: string, markerId: string, selectedAsset: string[]): Promise<Map> => {
    const response = await httpClient.post(`/maps/${mapId}/markers/${markerId}/add-assets`, selectedAsset);

    return Map.fromState(response.data);
}
