import React, {ChangeEvent, FormEvent, useState} from 'react';
import {toast} from 'react-toastify';
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {askForResetPassword} from "./account/use-cases";
import TextField from '@mui/material/TextField';
import IdentityPage from "./component/IdentityPage";
import {Form, SubmitButton} from "./component/Form";

const AskForResetPassword: React.FC = () => {
    const {formatMessage} = useIntl();
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');

    const onSubmit = async () => {
        try {
            await askForResetPassword(email);
            navigate(`/login`);
            toast.success(formatMessage({id: 'identity.passwordRenewalRequestHasBeenSent'}));
        } catch (e) {
            toast.error(formatMessage({id: 'formSubmissionError'}));
        }
    };

    return (
        <IdentityPage title={formatMessage({id: 'identity.askForResetPassword'})}>
            <Form
                noValidate
                onSubmit={(event: FormEvent<HTMLFormElement>) => event.preventDefault()}
            >
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={formatMessage({id: 'email'})}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    value={email}
                />
                <SubmitButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                >
                    {formatMessage({id: 'identity.buttonAskForNewPassword'})}
                </SubmitButton>

            </Form>
        </IdentityPage>
    );
}

export default AskForResetPassword;

