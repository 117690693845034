import React from "react";
import Autocomplete, {AutocompleteRenderInputParams} from '@mui/material/Autocomplete';
import {Box, styled, TextField} from "@mui/material";
import {iconSet, MarkerIcon} from "../../common";

const Option = styled("span")(({ theme }) => ({
    margin: theme.spacing(0, 1),
}));

const IconSetAutocomplete: React.FC<{
    value: string,
    className?: string,
    onChange: (selectedIcon: string) => void,
}> = ({
    value,
    className,
    onChange
}) => (
    <Autocomplete
        options={iconSet.toArray()}
        className={className}
        value={value}
        fullWidth
        renderOption={(props, icon) => (
            <Box component="li" marginX={1} marginY={1} {...props}>
                <MarkerIcon icon={icon}/>
                <Option>{icon}</Option>
            </Box>
        )}
        renderInput={(params: AutocompleteRenderInputParams) => {
            const inputProps = params.inputProps as { value?: string }
            return <TextField
                {...params}
                variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    startAdornment: <MarkerIcon icon={inputProps.value || ''}/>,
                }}
            />
        }}
        onChange={async (event: any, selectedIcon: string | null): Promise<void> => {
            onChange(selectedIcon ? selectedIcon : '');
        }}
    />
);
export {IconSetAutocomplete};