import React, {useContext, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import {MapActions,} from "../component/map/MapActions";
import {AuthContext} from "../../../../application/auth";
import MapListDrawer from "../component/map/MapListDrawer";
import {EditionMarkerDrawer} from "../component/map/marker/MarkerDrawer";
import {InteractiveMap} from "../component/map/InteractiveMap";
import MapDialog from "../component/map/MapDialog";
import {MapTitle} from "../component/map/MapTitle";
import MarkerCategoryFilterDrawer, {
    OpenMarkerCategoryFilterDrawerButton
} from "../component/marker-category/MarkerCategoryFilterDrawer";
import {PageLayout, useIsMobileDevice} from "../../../common";
import {InteractiveMapContext} from "../component/map/InteractiveMapContext";

const MapBoard: React.FC = () => {
    const context = useContext(InteractiveMapContext);
    const authContext = useContext(AuthContext);
    const isMobileDevice = useIsMobileDevice();
    const navigate = useNavigate();
    const {mapId} = useParams<{ mapId: string }>();

    useEffect(() => {
       context.getMap(mapId)
       context.getCartographerMaps()
    }, [mapId]);

    return (
        <>
            <PageLayout
                title={<MapTitle map={context.map} />}
                onOpenMenu={context.toggleMapListDrawer}
            >
                <MapListDrawer
                    maps={context.maps}
                    isDrawerOpened={context.isMapListOpened}
                    onCreateMapButtonClick={context.openMapCreationDialog}
                    onDrawerClose={context.closeMapListDrawer}
                />

                {
                    context.isMarkerCategoryFiltersButtonDisplayed(isMobileDevice) &&
                    <OpenMarkerCategoryFilterDrawerButton />
                }

                <EditionMarkerDrawer />
                <InteractiveMap
                    hideMapGeocoder={context.isMapControlsHidden(isMobileDevice)}
                    hideMapControls={context.isMapControlsHidden(isMobileDevice)}
                />

                <MarkerCategoryFilterDrawer/>

                <MapActions
                    isMobileDevice={isMobileDevice}
                    isPremiumCartographer={authContext.isPremiumCartographer()}
                    onCreateMapActionClick={context.openMapCreationDialog}
                    onEditActionClick={context.openMapEditionDialog}
                    onOpenGalleryActionClick={() => navigate(`/maps/${context.map.mapId()}/gallery`)}
                />
            </PageLayout>

            {context.mapProperties &&
                <MapDialog
                    map={context.mapProperties}
                    onFormSubmission={context.saveMap}
                    onClose={context.closeMapDialog}
                />
            }
        </>
    );
};

export default MapBoard;
